<template>
   <div>
      <div v-if="isLoading">
         <base-spinner></base-spinner>
      </div>

    <base-dialog :show="!!error" title="An error occured!" @close="handleError">
       <p>{{ error }}</p>
    </base-dialog>
 
    <div>
       <Sidebar />
       <div :style="{ 'margin-left': sidebarWidth }">
          <the-header/> 
          
          <transition name="slide">
            <section v-if="show">
               <header>
                  <h2>FURNISHING MAINTENACE</h2>
               </header>

               <base-card class="center">
                  <div class="actions">
                     <base-button link :to="createFurnishing">Add Furnishing</base-button>
                  </div>
               </base-card>

               <div class="center">
                  <table class="childText" id="customers">
                     <tr>
                        <th>Furnishing ID</th>
                        <th>Furnishing type ID</th>
                        <th>Description</th>
                        <th></th>
                     </tr>
                     <furnishing-item v-for="furnishing in receivedFurnishings" 
                        :key="furnishing.id" 
                        :furnishings_ID="furnishing.furnishings_ID"
                        :furnishings_type_furnishings_type_ID="furnishing.furnishings_type_furnishings_type_ID"
                        :description="furnishing.description">
                     </furnishing-item>
                  </table>
                  
               </div>
            </section>
         </transition>
       </div>
    </div>
   </div>
 </template>
 
 <script>
 import TheHeader from '../../../components/layout/TheHeader.vue';
 import Sidebar from '@/components/layout/sidebar/Sidebar';
 import { sidebarWidth } from '@/components/layout/sidebar/state';
 import FurnishingItem from '../../../components/furnishing/FurnishingItem.vue';
 
 export default {
    components: {
       TheHeader,
       Sidebar,
       FurnishingItem,
    },
    setup() {
       return { sidebarWidth }
    },
    data() {
       return {
          isLoading: false,
          error: null,
          show: false
    }
    },
    computed: {
       receivedFurnishings() {
          return this.$store.getters['furnishing/furnishings'];
       },
       createFurnishing() {
          return this.$route.path + '/create';
       },
    },
    created() {
       this.loadFurnishing();
    },
    methods: {
       async loadFurnishing() {
          this.isLoading = true;
 
          try {
             await this.$store.dispatch('furnishing/fetchFurnishings');
          } catch (error) {
             this.error = error.message || 'Something failed!';
          }
          
          this.isLoading = false;
       },
       handleError(){
          this.error = null;
       },
    },
    mounted() {
      this.show = true
   },
 }
 </script>
 
 <style>
 header {
   text-align: center;
 }
 
 ul {
   list-style: none;
   margin: 2rem auto;
   padding: 0;
   max-width: 30rem;
 }
 
 h3 {
   text-align: center;
 }
 .slide-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.slide-enter-active {
  transition: all 0.2s ease-out;
}

.slide-leave-active {
  transition: all 0.2s ease-in;
}

.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}
 </style>