<template>    
      <tr>
         <td>{{ reservations_ID }}</td>
         <td>{{ start_date }}</td>
         <td>{{ end_date }}</td>
         <td>{{ guests_ID }}</td>
         <td>{{ guestName }}</td>
         <td>{{ hotels_ID }}</td>
         <td>{{ hotelName }}</td>
         <td>{{ room_ID }}</td>
         <td>{{ roomName }}</td>
         <td>{{ price_day }}</td>
         <td>{{ calcAmount(start_date, end_date, price_day) }}</td>
      </tr>
 </template>

 <script>
import moment from 'moment'

 export default {
   props: [
    'key',
    'reservations_ID', 
    'start_date', 
    'end_date', 
    'guests_ID', 
    'guestName', 
    'hotels_ID', 
    'hotelName', 
    'room_ID', 
    'roomName', 
    'price_day', 
  ],
  methods: {
   calcAmount(startDate, endDate, price) {
      let startMoment = moment(startDate);
      let endMoment = moment(endDate);
      let diff = endMoment.diff(startMoment,'days');
      return diff * price;
   }
  }
 }
 </script>
 
 <style scoped>
 li {
   margin: 1rem 0;
   border: 1px solid #424242;
   border-radius: 12px;
   padding: 1rem;
 }
 
 h3 {
   font-size: 1.5rem;
 }
 
 h3,
 h4 {
   margin: 0.5rem 0;
 }
 
 div {
   margin: 0.5rem 0;
 }
 
 .actions {
   display: flex;
   justify-content: center;
 }

 .parent {
  padding: 1rem 1rem;
  text-align: center;
}
.childText {
  display: inline-block;
  vertical-align: middle;
}

.imageSize {
   border-radius: 8px;
   width: 100px;
}
 </style>