<template>
   <div>
      <div v-if="isLoading">
         <base-spinner></base-spinner>
      </div>

      <base-dialog :show="!!error" title="An error occured!" @close="handleError">
         <p>{{ error }}</p>
      </base-dialog>
   
      <div>
         <Sidebar />
         <div :style="{ 'margin-left': sidebarWidth }">
            <the-header/> 
            
            <transition name="slide">
               <section v-if="show">
                  <header>
                     <h2>PRICE MAINTENACE</h2>
                  </header>

                  <base-card class="center">
                     <div class="actions">
                        <base-button link :to="createPrice">Add Price</base-button>
                     </div>
                  </base-card>

                  <div class="center">
                     <table class="childText" id="customers">
                        <tr>
                           <th>Price ID</th>
                           <th>Description</th>
                           <th>Price a day (CHF)</th>
                           <th></th>
                        </tr>
                        <price-item v-for="price in receivedPrices" 
                           :key="price.id" 
                           :price_class_ID="price.price_class_ID"
                           :description="price.description"
                           :price_day="price.price_day">
                        </price-item>
                     </table>
                  </div>

               </section>
            </transition>
         </div>
      </div>
   </div>
</template>

<script>
import TheHeader from '../../../components/layout/TheHeader.vue';
import Sidebar from '@/components/layout/sidebar/Sidebar';
import { sidebarWidth } from '@/components/layout/sidebar/state';
import PriceItem from '../../../components/price/PriceItem';

export default {
   components: {
      TheHeader,
      Sidebar,
      PriceItem,
   },
   setup() {
      return { sidebarWidth }
   },
   data() {
      return {
         isLoading: false,
         error: null,
         show: false
   }
   },
   computed: {
      receivedPrices() {
         return this.$store.getters['prices/prices'];
      },
      createPrice() {
         return this.$route.path + '/create';
      },
   },
   created() {
      this.loadPrice();
   },
   methods: {
      async loadPrice() {
         this.isLoading = true;

         try {
            await this.$store.dispatch('prices/fetchPrices');
         } catch (error) {
            this.error = error.message || 'Something failed!';
         }
         
         this.isLoading = false;
      },
      handleError(){
         this.error = null;
      },
   },
   mounted() {
    this.show = true
  },
}
</script>

<style>
header {
text-align: center;
}

ul {
list-style: none;
margin: 2rem auto;
padding: 0;
max-width: 30rem;
}

h3 {
text-align: center;
}
.center {
vertical-align: middle;
}
.slide-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.slide-enter-active {
  transition: all 0.2s ease-out;
}

.slide-leave-active {
  transition: all 0.2s ease-in;
}

.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
   