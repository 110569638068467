import config from '../../../config'

export default {
   async fetchGuests(context) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/view-guests`, {
         method: 'GET',
      });
      
      
      if (!response.ok) {
         const error = new Error(response.message || 'Server is not responding!');
         throw error;
      }

      
      const responseData = await response.json();
      const guests = [];
      
      for (const key in responseData) {
         const guest = {
            id: key,
            guests_ID: responseData[key].guests_ID,
            name: responseData[key].name,
            surname: responseData[key].surname,
            phone: responseData[key].phone,
            email: responseData[key].email,
            street: responseData[key].street,
            streetnumber: responseData[key].streetnumber,
            locality_ID: responseData[key].locality_ID,
            postal_code: responseData[key].postal_code,
            place: responseData[key].place
         }
         guests.push(guest);      
      }
      context.commit('setGuest', guests);
   },
   async registerGuest(context, data) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/guest?localityId=${data.localityID}&name=${data.name}&surname=${data.surname}&phone=${data.phone}&email=${data.email}&street=${data.street}&streetnumber=${data.streetnumber}` , {
         method: 'POST',
      });

      const responseData = await response.json();

      if (!response.ok) {
         const error = new Error(responseData.message || 'Failed to create a new guest!');
         throw error;
      }
   },
   async updateGuest(context, data) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/guest?guestsId=${data.guests_ID}&localityId=${data.localityID}&name=${data.name}&surname=${data.surname}&phone=${data.phone}&email=${data.email}&street=${data.street}&streetnumber=${data.streetnumber}` , {
         method: 'PUT',
      });

      const responseData = await response.json();

      if (!response.ok) {
         const error = new Error(responseData.message || 'Failed to create a new guest!');
         throw error;
      }
   },
};
