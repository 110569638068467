<template>
<div>
   <div v-if="isLoading">
      <base-spinner></base-spinner>
   </div>

   <base-dialog :show="!!error" title="An error occured!" @close="handleError">
      <p>{{ error }}</p>
   </base-dialog>

   <div>
      <Sidebar />
      <div :style="{ 'margin-left': sidebarWidth }">
         <the-header/> 

         <transition name="slide">
            <section v-if="show">

               <header>
                  <h2>CATEGORY MAINTENACE</h2>
               </header>

               <base-card class="center">
                  <div class="actions">
                     <base-button link :to="createCategory">Add Category</base-button>
                  </div>
               </base-card>

               <div class="center">
                  <table class="childText" id="customers">
                     <tr>
                        <th>Category ID</th>
                        <th>Description</th>
                        <th></th>
                     </tr>
                     <category-item v-for="category in receivedCategory" 
                        :key="category.id" 
                        :star_category_ID="category.star_category_ID"
                        :description="category.description">
                     </category-item>
                  </table>
               </div>
            
            </section>
         </transition>
      </div>
   </div>
</div>
</template>

<script>
import TheHeader from '../../../components/layout/TheHeader.vue';
import Sidebar from '@/components/layout/sidebar/Sidebar';
import { sidebarWidth } from '@/components/layout/sidebar/state';
import CategoryItem from '../../../components/category/CategoryItem.vue';

export default {
   components: {
      TheHeader,
      Sidebar,
      CategoryItem,
   },
   setup() {
      return { sidebarWidth }
   },
   data() {
      return {
         isLoading: false,
         error: null,
         show: false
   }
   },
   computed: {
      receivedCategory() {
         return this.$store.getters['category/categories'];
      },
      createCategory() {
         return this.$route.path + '/create';
      },
   },
   created() {
      this.loadCategory();
   },
   methods: {
      async loadCategory() {
         this.isLoading = true;

         try {
            await this.$store.dispatch('category/fetchCategory');
         } catch (error) {
            this.error = error.message || 'Something failed!';
         }
         
         this.isLoading = false;
      },
      handleError(){
         this.error = null;
      },
   },
   mounted() {
    this.show = true
  },
}
</script>

<style>
header {
text-align: center;
}

ul {
list-style: none;
margin: 2rem auto;
padding: 0;
max-width: 30rem;
}

h3 {
text-align: center;
}
.center {
vertical-align: middle;
}
.slide-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.slide-enter-active {
  transition: all 0.2s ease-out;
}

.slide-leave-active {
  transition: all 0.2s ease-in;
}

.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
