<template>
   <div>
      <div v-if="isLoading">
         <base-spinner></base-spinner>
      </div>

      <base-dialog :show="!!error" title="An error occured!" @close="handleError">
         <p>{{ error }}</p>
      </base-dialog>
   
      <div>
         <Sidebar />
         <div :style="{ 'margin-left': sidebarWidth }">
            <the-header/> 
            <transition name="slide">
               <section v-if="show">

                  <header>
                     <h2>GUEST MAINTENACE</h2>
                  </header>

                  <base-card class="center">
                     <div class="actions">
                        <base-button link :to="createGuest">Add Guest</base-button>
                     </div>
                  </base-card>
                  
                  <div class="center">
                     <table class="childText" id="customers">
                        <tr>
                           <th>Guest ID</th>
                           <th>Name</th>
                           <th>Surname</th>
                           <th>Phone</th>
                           <th>Email</th>
                           <th>Street</th>
                           <th>Street Number</th>
                           <th>Locality ID</th>
                           <th>Postalcode</th>
                           <th>Place</th>
                           <th></th>
                        </tr>
                        <guest-item v-for="guest in receivedGuests" 
                           :key="guest.id" 
                           :guests_ID="guest.guests_ID"
                           :name="guest.name"
                           :surname="guest.surname"
                           :phone="guest.phone"
                           :email="guest.email"
                           :street="guest.street"
                           :streetnumber="guest.streetnumber"
                           :locality_ID="guest.locality_ID"
                           :postal_code="guest.postal_code"
                           :place="guest.place"
                           >
                        </guest-item>
                     </table>
                  </div>

               </section>
            </transition>
         </div>
      </div>
   </div>
</template>

<script>
import TheHeader from '../../../components/layout/TheHeader.vue';
import Sidebar from '@/components/layout/sidebar/Sidebar';
import { sidebarWidth } from '@/components/layout/sidebar/state';
import GuestItem from '../../../components/guest/GuestItem.vue';

export default {
components: {
   TheHeader,
   Sidebar,
   GuestItem,
},
setup() {
   return { sidebarWidth }
},
data() {
   return {
      isLoading: false,
      error: null,
      show: false
}
},
computed: {
   receivedGuests() {
      return this.$store.getters['guests/guests'];
   },
   createGuest() {
      return this.$route.path + '/create';
   },
},
created() {
   this.loadGuests();
},
methods: {
   async loadGuests() {
      this.isLoading = true;

      try {
         await this.$store.dispatch('guests/fetchGuests');
      } catch (error) {
         this.error = error.message || 'Something failed!';
      }
      
      this.isLoading = false;
   },
   handleError(){
      this.error = null;
   },
},
mounted() {
    this.show = true
  },
}
</script>

<style>
header {
text-align: center;
}

ul {
list-style: none;
margin: 2rem auto;
padding: 0;
max-width: 30rem;
}

h3 {
text-align: center;
}
.center {
vertical-align: middle;
}
.slide-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.slide-enter-active {
  transition: all 0.2s ease-out;
}

.slide-leave-active {
  transition: all 0.2s ease-in;
}

.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
   