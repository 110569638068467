<template>
<div>

   <base-dialog :show="!!error" title="An error occured!" @close="handleError">
      <p>{{ error }}</p>
   </base-dialog>

   <div>
      <Sidebar />
      <div :style="{ 'margin-left': sidebarWidth }">
         <the-header/> 

         <transition name="slide">
            <section v-if="show">
               
                  <header>
                     <h2>RESERVATION LIST</h2>
                  </header>
                  
                  <div class="center">
                     <table class="childText" id="customers">
                        <tr>
                           <th>Reservation ID</th>
                           <th>Start date</th>
                           <th>End Date</th>
                           <th>Guest ID</th>
                           <th>Guests name</th>
                           <th>Hotel ID</th>
                           <th>Hotels name</th>
                           <th>Room ID</th>
                           <th>Rooms name</th>
                           <th>Price CHF / day</th>
                           <th>Amount</th>
                        </tr>
                        <reservation-item v-for="reservation in receivedReservations" 
                           :key="reservation.id" 
                           :reservations_ID="reservation.reservations_ID"
                           :start_date="reservation.start_date"
                           :end_date="reservation.end_date"
                           :guests_ID="reservation.guests_ID"
                           :guestName="reservation.guestName"
                           :hotels_ID="reservation.hotels_ID"
                           :hotelName="reservation.hotelName"
                           :room_ID="reservation.room_ID"
                           :roomName="reservation.roomName"
                           :price_day="reservation.price_day"
                           >
                        </reservation-item>
                     </table>
                  </div>
            </section>
         </transition>
      </div>
   </div>
</div>
</template>

<script>
import TheHeader from '../../../components/layout/TheHeader.vue';
import Sidebar from '@/components/layout/sidebar/Sidebar';
import { sidebarWidth } from '@/components/layout/sidebar/state';
import ReservationItem from '../../../components/reservation/ReservationItem.vue';

export default {
   components: {
      TheHeader,
      Sidebar,
      ReservationItem,
   },
   setup() {
      return { sidebarWidth }
   },
   data() {
      return {
         isLoading: false,
         error: null,
         show: false
   }
   },
   computed: {
      receivedReservations() {
         return this.$store.getters['reservation/reservations'];
      },
   },
   created() {
      this.loadReservations();
   },
   methods: {
      async loadReservations() {
         this.isLoading = true;

         try {
            await this.$store.dispatch('reservation/fetchReservations');
         } catch (error) {
            this.error = error.message || 'Something failed!';
         }
         
         this.isLoading = false;
      },
      handleError(){
         this.error = null;
      },
   },
   mounted() {
    this.show = true
  },
}
</script>

<style>
header {
text-align: center;
}

ul {
list-style: none;
margin: 2rem auto;
padding: 0;
max-width: 30rem;
}

h3 {
text-align: center;
}

.center {
   vertical-align: middle;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #3d008d;
  color: white;
}
.slide-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.slide-enter-active {
  transition: all 0.2s ease-out;
}

.slide-leave-active {
  transition: all 0.2s ease-in;
}

.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
