import config from '../../../config'

export default {
   async fetchFurnishings(context) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/furnishings`, {
         method: 'GET',
      });
      
      
      if (!response.ok) {
         const error = new Error(response.message || 'Server is not responding!');
         throw error;
      }

      
      const responseData = await response.json();
      const furnishings = [];
      
      for (const key in responseData) {
         const furnishing = {
            id: key,
            furnishings_ID: responseData[key].furnishings_ID,
            furnishings_type_furnishings_type_ID: responseData[key].furnishings_type_furnishings_type_ID,
            description: responseData[key].description,
         }
         furnishings.push(furnishing);      
      }
      context.commit('setFurnishings', furnishings);
   },
   async registerFurnishing(context, data) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/furnishings?furnishingTypeId=${data.type}&description=${data.name}` , {
         method: 'POST',
      });

      const responseData = await response.json();

      if (!response.ok) {
         const error = new Error(responseData.message || 'Failed to create a new furnishing!');
         throw error;
      }
   },
   async updateFurnishing(context, data) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/furnishings?furnishingId=${data.furnishings_ID}&furnishingTypeId=${data.type}&description=${data.name}` , {
         method: 'PUT',
      });

      const responseData = await response.json();

      if (!response.ok) {
         const error = new Error(responseData.message || 'Failed to update your furnishing!');
         throw error;
      }
   },
};
