<template>
   <base-card>
    <h2>Find your Hotel</h2>
    <span class="filter-option">
       <input type="checkbox" id="oneStar" checked @change="setFilter">
       <label for="oneStar">1-Stern</label>
    </span>
    <span class="filter-option">
       <input type="checkbox" id="twoStar" checked @change="setFilter">
       <label for="twoStar">2-Stern</label>
    </span>
    <span class="filter-option">
       <input type="checkbox" id="threeStar" checked @change="setFilter">
       <label for="threeStar">3-Stern</label>
    </span>
    <span class="filter-option">
       <input type="checkbox" id="fourStar" checked @change="setFilter">
       <label for="fourStar">4-Stern</label>
    </span>
    <span class="filter-option">
       <input type="checkbox" id="fiveStar" checked @change="setFilter">
       <label for="fiveStar">5-Stern</label>
    </span>
   </base-card>
 </template>
 
 <script>
export default {
   emits: ['change-filter'],
   data() {
      return {
         filters: {
            oneStar: true,
            twoStar: true,
            threeStar: true,
            fourStar: true,
            fiveStar: true
         }
      };
   },
   methods: {
      setFilter(event) {
         const inputId = event.target.id;
         const isActive = event.target.checked;
         const updatedFilters = {
            ...this.filters,
            [inputId]: isActive
         }
         this.filters = updatedFilters;
         this.$emit('change-filter', updatedFilters);
      }
   }
}
</script>

<style scoped>
h2 {
margin: 0.5rem 0;
}

.filter-option {
margin-right: 1rem;
}

.filter-option label,
.filter-option input {
vertical-align: middle;
}

.filter-option label {
margin-left: 0.25rem;
}

.filter-option.active label {
font-weight: bold;
}
</style>