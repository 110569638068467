<template>
  <div>
   <Sidebar />
      <div :style="{ 'margin-left': sidebarWidth }">
         <the-header/> 
         <transition name="slide">  
          <section v-if="show">
            <base-card>
              <div>
                <label for="dashboarc">Overview Regristrations</label>
                  <table class="center" id="customers">
                    <tr class="center">
                      <th class="centerHeader">Attribute</th>
                      <th class="centerHeader">Count</th>
                    </tr>
                    <tr>
                      <td>Hotels</td>
                      <td>{{ countHotels }}</td>
                    </tr>
                    <tr>
                      <td>Categories</td>
                      <td>{{ countCategories }}</td>
                    </tr>
                    <tr>
                      <td>Furnishings</td>
                      <td>{{ countFurnishing }}</td>
                    </tr>
                    <tr>
                      <td>Guests</td>
                      <td>{{ countGuests }}</td>
                    </tr>
                    <tr>
                      <td>Localities</td>
                      <td>{{ countLocality }}</td>
                    </tr>
                    <tr>
                      <td>Prices</td>
                      <td>{{ countPrices }}</td>
                    </tr>
                    <tr>
                      <td>Rooms</td>
                      <td>{{ countRooms }}</td>
                    </tr>
                    <tr>
                      <td>Reservations</td>
                      <td>{{ countReservations }}</td>
                    </tr>
                  </table>
              </div>
            </base-card>
          </section>
        </transition>
      </div>
   </div>
</template>

<script>
import TheHeader from '../../components/layout/TheHeader.vue';
import Sidebar from '@/components/layout/sidebar/Sidebar';
import { sidebarWidth } from '@/components/layout/sidebar/state';

export default {
   components: {
      TheHeader,
      Sidebar
   },
   data() {
      return {
         show: false
   }
   },
   setup() {
    return { sidebarWidth }
  },
  mounted() {
    this.show = true
  },
  methods: {
    async loadData() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('hotels/fetchHotels');
        await this.$store.dispatch('category/fetchCategory');
        await this.$store.dispatch('furnishing/fetchFurnishings');
        await this.$store.dispatch('guests/fetchGuests');
        await this.$store.dispatch('localities/fetchLocality');
        await this.$store.dispatch('prices/fetchPrices');
        await this.$store.dispatch('reservation/fetchReservations');
        await this.$store.dispatch('rooms/fetchRooms');
      } catch (error) {
        this.error = error.message || 'Something failed!';
      }
      
      this.isLoading = false;
    },
    getItemCount(dict) {
      let arr = [];

      for (var key in dict) {
          arr.push( [ key, dict[key] ] );
      }
      return arr.length;
    },
    handleError(){
        this.error = null;
    },
    setFilters(updatedFilters) {
          this.activeFilters = updatedFilters;
    },
    isAdminCheck() {
        if (this.$route.path.includes('admin'))
        {
          this.isAdmin = true;
        }
        return this.isAdmin;
    }
  },
  created() {
      this.loadData();
  },
  computed: {
    countHotels: function() {
      return this.getItemCount(this.$store.getters['hotels/hotels']);
      },
    countCategories: function() {
      return this.getItemCount(this.$store.getters['category/categories']);
    },
    countFurnishing: function() {
      return this.getItemCount(this.$store.getters['furnishing/furnishings']);
    },
    countGuests: function() {
      return this.getItemCount(this.$store.getters['guests/guests']);
    },
    countLocality: function() {
      return this.getItemCount(this.$store.getters['localities/localities']);
    },
    countPrices: function() {
      return this.getItemCount(this.$store.getters['prices/prices']);
    },
    countRooms: function() {
      return this.getItemCount(this.$store.getters['rooms/rooms']);
    },
    countReservations: function() {
      return this.getItemCount(this.$store.getters['reservation/reservations']);
    },
  }
};
</script>

<style>
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.slide-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.slide-enter-active {
  transition: all 0.2s ease-out;
}

.slide-leave-active {
  transition: all 0.2s ease-in;
}

.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}
td {
  text-align: left;
}
</style>