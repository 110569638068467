<template>
  <tr>
    <td>{{ price_class_ID }}</td>
    <td>{{ description }}</td>
    <td>{{ price_day }}</td>
    <td>
        <div class="actions">
          <base-button link :to="priceEditLink">Edit</base-button>
        </div>
    </td>
  </tr>
 </template>

 <script>
 export default {
   props: [
    'key',
    'price_class_ID', 
    'description',
    'price_day' 
  ],
   computed: {
      priceEditLink() {
         return this.$route.path + '/update/' + this.price_class_ID;
      },
   },
 }
 </script>
 
 <style scoped>
 li {
   margin: 1rem 0;
   border: 1px solid #424242;
   border-radius: 12px;
   padding: 1rem;
 }
 
 h3 {
   font-size: 1.5rem;
 }
 
 h3,
 h4 {
   margin: 0.5rem 0;
 }
 
 div {
   margin: 0.5rem 0;
 }
 
 .actions {
   display: flex;
   justify-content: center;
 }

 .parent {
  padding: 1rem 1rem;
  text-align: center;
}
.childText {
  display: inline-block;
  vertical-align: middle;
}

.imageSize {
   border-radius: 8px;
   width: 100px;
}
 </style>