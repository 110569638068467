<template>
<div>
   <div v-if="isLoading">
      <base-spinner></base-spinner>
   </div>

   <base-dialog :show="!!error" title="An error occured!" @close="handleError">
      <p>{{ error }}</p>
   </base-dialog>

   <div>
      <Sidebar />
      <div :style="{ 'margin-left': sidebarWidth }">
         <the-header/> 
         
         <transition name="slide">
            <section v-if="show">
                  <header>
                     <h2>ROOM MAINTENACE</h2>
                  </header>

                  <base-card class="center">
                     <div class="actions">
                        <base-button link :to="createRoom">Add Room</base-button>
                     </div>
                  </base-card>

                  <div class="center">
                     <table class="childText" id="customers">
                        <tr>
                           <th>Room ID</th>
                           <th>Name</th>
                           <th>Priceclass ID</th>
                           <th>Description</th>
                           <th>Price per day (CHF)</th>
                        </tr>
                        <room-item v-for="room in receivedRooms" 
                           :key="room.id" 
                           :room_ID="room.room_ID"
                           :name="room.name"
                           :price_class_ID="room.price_class_ID"
                           :description="room.description"
                           :price_day="room.price_day">
                        </room-item>
                     </table>
                  </div>
            </section>
         </transition>
      </div>
   </div>
</div>
</template>

<script>
import TheHeader from '../../../components/layout/TheHeader.vue';
import Sidebar from '@/components/layout/sidebar/Sidebar';
import { sidebarWidth } from '@/components/layout/sidebar/state';
import RoomItem from '../../../components/room/RoomItem';

export default {
   components: {
      TheHeader,
      Sidebar,
      RoomItem,
   },
   setup() {
      return { sidebarWidth }
   },
   data() {
      return {
         isLoading: false,
         error: null,
         show: false
   }
   },
   computed: {
      receivedRooms() {
         return this.$store.getters['rooms/rooms'];
      },
      createRoom() {
         return this.$route.path + '/create';
      },
   },
   created() {
      this.loadRooms();
   },
   methods: {
      async loadRooms() {
         this.isLoading = true;

         try {
            await this.$store.dispatch('rooms/fetchRooms');
         } catch (error) {
            this.error = error.message || 'Something failed!';
         }
         
         this.isLoading = false;
      },
      handleError(){
         this.error = null;
      },
   },
   mounted() {
    this.show = true
  },
}
</script>

<style>
header {
text-align: center;
}

ul {
list-style: none;
margin: 2rem auto;
padding: 0;
max-width: 30rem;
}

h3 {
text-align: center;
}
.slide-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.slide-enter-active {
  transition: all 0.2s ease-out;
}

.slide-leave-active {
  transition: all 0.2s ease-in;
}

.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
