<template>
   <div>

      <div v-if="isLoading">
         <base-spinner></base-spinner>
      </div>

      <base-dialog :show="!!error" title="An error occured!" @close="handleError">
         <p>{{ error }}</p>
      </base-dialog>
   
      <div>
         <Sidebar />
         <div :style="{ 'margin-left': sidebarWidth }">
            <the-header/> 
            
            <transition name="slide">
               <section v-if="show">
                  <header>
                     <h2>LOCALITY MAINTENACE</h2>
                  </header>

                  <base-card class="center">
                     <div class="actions">
                        <base-button link :to="createLocality">Add Locality</base-button>
                     </div>
                  </base-card>

                  <div class="center">
                     <table class="childText" id="customers">
                        <tr>
                           <th>Locality ID</th>
                           <th>Postal code</th>
                           <th>Place</th>
                           <th></th>
                        </tr>
                        <locality-item v-for="locality in receivedLocalities" 
                           :key="locality.id" 
                           :locality_ID="locality.locality_ID"
                           :postal_code="locality.postal_code"
                           :place="locality.place">
                        </locality-item>
                     </table>
                  </div>
               </section>
            </transition>
         </div>
      </div>
   </div>
   </template>
   
   <script>
   import TheHeader from '../../../components/layout/TheHeader.vue';
   import Sidebar from '@/components/layout/sidebar/Sidebar';
   import { sidebarWidth } from '@/components/layout/sidebar/state';
   import LocalityItem from '../../../components/localities/LocalityItem.vue';
   
   export default {
      components: {
         TheHeader,
         Sidebar,
         LocalityItem,
      },
      setup() {
         return { sidebarWidth }
      },
      data() {
         return {
            isLoading: false,
            error: null,
            show: false
      }
      },
      computed: {
         receivedLocalities() {
            return this.$store.getters['localities/localities'];
         },
         createLocality() {
            return this.$route.path + '/create';
         },
      },
      created() {
         this.loadLocalities();
      },
      methods: {
         async loadLocalities() {
            this.isLoading = true;
   
            try {
               await this.$store.dispatch('localities/fetchLocality');
            } catch (error) {
               this.error = error.message || 'Something failed!';
            }
            
            this.isLoading = false;
         },
         handleError(){
            this.error = null;
         },
      },
      mounted() {
         this.show = true
      },
   }
   </script>
   
   <style>
   header {
   text-align: center;
   }
   
   ul {
   list-style: none;
   margin: 2rem auto;
   padding: 0;
   max-width: 30rem;
   }
   
   h3 {
   text-align: center;
   }
   .slide-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.slide-enter-active {
  transition: all 0.2s ease-out;
}

.slide-leave-active {
  transition: all 0.2s ease-in;
}

.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}
   </style>