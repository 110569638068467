import config from '../../../config'

export default {
   async fetchLocality(context) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/locality`, {
         method: 'GET',
      });
      
      
      if (!response.ok) {
         const error = new Error(response.message || 'Server is not responding!');
         throw error;
      }

      
      const responseData = await response.json();
      const localities = [];
      
      for (const key in responseData) {
         const locality = {
            id: key,
            locality_ID: responseData[key].locality_ID,
            postal_code: responseData[key].postal_code,
            place: responseData[key].place,
         }
         localities.push(locality);      
      }
      context.commit('setLocality', localities);
   },
   async registerLocality(context, data) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/locality?postalCode=${data.postal}&place=${data.name}` , {
         method: 'POST',
      });

      const responseData = await response.json();

      if (!response.ok) {
         const error = new Error(responseData.message || 'Failed to create a new locality!');
         throw error;
      }
   },
   async updateLocality(context, data) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/locality?localityId=${data.locality_ID}&postalCode=${data.postal}&place=${data.name}` , {
         method: 'PUT',
      });

      const responseData = await response.json();

      if (!response.ok) {
         const error = new Error(responseData.message || 'Failed to update your locality!');
         throw error;
      }
   },
};