import config from '../../../config'

export default {
   async fetchFurnishingType(context) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/furnishingtype`, {
         method: 'GET',
      });
      
      
      if (!response.ok) {
         const error = new Error(response.message || 'Server is not responding!');
         throw error;
      }

      
      const responseData = await response.json();
      const furnishingTypes = [];
      
      for (const key in responseData) {
         const furnishing = {
            id: key,
            furnishings_type_ID: responseData[key].furnishings_type_ID,
            description: responseData[key].description,
         }
         furnishingTypes.push(furnishing);      
      }
      context.commit('setFurnishingTypes', furnishingTypes);
   }
};