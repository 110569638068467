<template>
   <div>
   <Sidebar />
      <div :style="{ 'margin-left': sidebarWidth }">
         <the-header/> 

         <transition name="slide">
            <section v-if="show">
               <base-card>
                  <h2 v-if="!isUpdate">Register a Guest!</h2>
                  <h2 v-else>Update your Guest!</h2>
                  <guest-form @save-data="saveData"></guest-form>
               </base-card>
            </section>
         </transition>
      </div>
   </div>
 </template>

<script>
import TheHeader from '../../../components/layout/TheHeader.vue';
import Sidebar from '@/components/layout/sidebar/Sidebar';
import { sidebarWidth } from '@/components/layout/sidebar/state';
import GuestForm from '../../../components/guest/GuestForm.vue'
import BaseCard from '../../../components/ui/BaseCard.vue'

export default {
   components: {
      GuestForm,
      BaseCard,
      TheHeader,
      Sidebar
   },
   methods: {
      saveData(data) {
         if (!data.guests_ID) {
            this.$store.dispatch('guests/registerGuest', data);
         } else {
            this.$store.dispatch('guests/updateGuest', data);
         }
         
         this.$router.replace('/admin/guests');
      },
      async loadLocality() {
         this.isLoading = true;

         try {
            await this.$store.dispatch('localities/fetchLocality');
         } catch (error) {
            this.error = error.message || 'Something failed!';
         }
         
         this.isLoading = false;
      },
      handleError(){
         this.error = null;
      },
   },
   setup() {
      return { sidebarWidth }
   },
   data() {
      return {
         isLoading: false,
         error: null,
         show: false,
         isUpdate: false
      }
   },
   created() {
      this.loadLocality();

      if (this.$route.params.update) {
         this.isUpdate = true;
      } else {
         this.isUpdate = false;
      }
   },
   mounted() {
    this.show = true
  },
}
</script>

<style scoped>
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.slide-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.slide-enter-active {
  transition: all 0.2s ease-out;
}

.slide-leave-active {
  transition: all 0.2s ease-in;
}

.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
