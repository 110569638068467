<template>
   <div>
   <Sidebar />
      <div :style="{ 'margin-left': sidebarWidth }">
         <the-header/> 

         <transition name="slide">
            <section v-if="show">
               <base-card>
                  <base-dialog :show="!!error" title="An error occured!" @close="handleError">
                     <p>{{ error }}</p>
                  </base-dialog>

                  <h2>Register a room!</h2>
                  <room-form @save-data="saveData"></room-form>
               </base-card>
            </section>
         </transition>
      </div>
   </div>
 </template>

<script>
import TheHeader from '../../../components/layout/TheHeader.vue';
import Sidebar from '@/components/layout/sidebar/Sidebar';
import { sidebarWidth } from '@/components/layout/sidebar/state';
import RoomForm from '../../../components/room/RoomForm.vue'
import BaseCard from '../../../components/ui/BaseCard.vue'

export default {
   components: {
      RoomForm,
      BaseCard,
      TheHeader,
      Sidebar
   },
   methods: {
      saveData(data) {
         this.$store.dispatch('rooms/registerRoom', data);

         if (data.furnishings.length > 20) {
            this.$store.dispatch('rooms/registerRoomFurnishing', data);
         }
         this.$router.replace('/admin/rooms');
      },
      async loadHotels() {
         this.isLoading = true;

         try {
            await this.$store.dispatch('hotels/fetchHotels');
         } catch (error) {
            this.error = error.message || 'Something failed!';
         }
         
         this.isLoading = false;
      },
      async loadRoomFurnishings() {
         this.isLoading = true;

         try {
            await this.$store.dispatch('rooms/fetchRoomFurnishing');
         } catch (error) {
            this.error = error.message || 'Something failed!';
         }
         
         this.isLoading = false;
      },
      handleError(){
         this.error = null;
      },
      async loadPrices() {
         this.isLoading = true;

         try {
            await this.$store.dispatch('prices/fetchPrices');
         } catch (error) {
            this.error = error.message || 'Something failed!';
         }
         
         this.isLoading = false;
      },
   },
   created() {
      this.loadHotels();
      this.loadPrices();
      this.loadRoomFurnishings();
   },
   setup() {
      return { sidebarWidth }
   },
   data() {
      return {
         isLoading: false,
         error: null,
         show: false
      }
   },
   mounted() {
    this.show = true
  },
}
</script>

<style scoped>
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.slide-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.slide-enter-active {
  transition: all 0.2s ease-out;
}

.slide-leave-active {
  transition: all 0.2s ease-in;
}

.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
