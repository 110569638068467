import { createStore } from 'vuex';

import HotelsModule from './modules/hotels/index.js';
import FurnishingsModule from './modules/furnishings/index.js';
import FurnishingTypesModule from './modules/furnishingTypes/index.js';
import Localities from './modules/locality/index.js';
import Category from './modules/category/index.js';
import Prices from './modules/price/index.js';
import Rooms from './modules/room/index.js';
import Reservation from './modules/reservation/index.js';
import Guests from './modules/guest/index.js';

const store = createStore({
   modules: {
      hotels: HotelsModule,
      furnishing: FurnishingsModule,
      furnishingTypes: FurnishingTypesModule,
      localities: Localities,
      category: Category,
      prices: Prices,
      rooms: Rooms,
      reservation: Reservation,
      guests: Guests,
   },
});

export default store;