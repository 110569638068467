<script>
import SidebarLink from './SidebarLink'
import { collapsed, toggleSidebar, sidebarWidth } from './state'
export default {
  props: {},
  components: { SidebarLink },
  setup() {
    return { collapsed, toggleSidebar, sidebarWidth }
  }
}
</script>

<template>
  <div class="sidebar" :style="{ width: sidebarWidth }">
    <div class="makeSpace"></div>

    <SidebarLink to="/admin" icon="fas fa-home">Dashboard</SidebarLink>
    <SidebarLink to="/admin/hotels" icon="fas fa-columns">Hotels</SidebarLink>
    <SidebarLink to="/admin/rooms" icon="fas fa-columns">Rooms</SidebarLink>
    <SidebarLink to="/admin/guests" icon="fas fa-columns">Guests</SidebarLink>
    <SidebarLink to="/admin/furnishings" icon="fas fa-columns">Furnishing</SidebarLink>
    <SidebarLink to="/admin/localities" icon="fas fa-columns">Localities</SidebarLink>
    <SidebarLink to="/admin/categories" icon="fas fa-columns">Categories</SidebarLink>
    <SidebarLink to="/admin/prices" icon="fas fa-columns">Price Classes</SidebarLink>
    <SidebarLink to="/admin/reservations" icon="fas fa-columns">Reservations</SidebarLink>
    
    <span
      class="collapse-icon"
      :class="{ 'rotate-180': collapsed }"
      @click="toggleSidebar"
    >
      <i class="fas fa-angle-double-left" />
    </span>
  </div>
</template>

<style>
:root {
  --sidebar-bg-color: #3d008d;
  --sidebar-item-hover: #2c0066;
  --sidebar-item-active: #5e00d8;
}
</style>

<style scoped>
.makeSpace {
  padding-top: 70px;
}

.sidebar {
  color: white;
  background-color: var(--sidebar-bg-color);
  float: left;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0.5em;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
}
.sidebar h1 {
  height: 2.5em;
}
.collapse-icon {
  position: absolute;
  bottom: 0;
  padding: 0.75em;
  color: rgba(255, 255, 255, 0.7);
  transition: 0.2s linear;
}
.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}
</style>