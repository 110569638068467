<template>
<form @submit.prevent="submitForm">
   <div class="form-control" :class="{invalid: !furnishingName.isValid}">
      <label for="furnishingname">Furnishing name</label>
      <input type="text" id="furnishingname" v-model.trim="furnishingName.val" @blur="clearValidity('furnishingName')"/>
      <p v-if="!furnishingName.isValid">Furnishing name must not be empty or longer than 10 characters.</p>
   </div>
   <div class="form-control">
      <label for="hotel">Hotel</label>
      <select v-model.trim="furnishingType.val">
         <option v-for="furnishingType in receivedFurnishingTypes"
            :key="furnishingType.id" 
            :value="furnishingType.furnishings_type_ID">{{ furnishingType.description }}
         </option>
      </select>
   </div>
   <p v-if="!formIsValid">Please fix the above errors and submit again.</p>
   <base-button v-if="!formIsUpdate">Register</base-button>
   <base-button v-else>Update</base-button>
</form>
</template>

<script>
export default {
   emits: ['save-data'],
   data() {
      return {
         furnishings_ID: {
            val: '',
            isValid: true
         },
         furnishingName: {
            val: '',
            isValid: true
         },
         furnishingType: {
            val: '',
            isValid: true
         },
         formIsValid: true,
         formIsUpdate: false
      }
   },
   computed: {
      receivedFurnishingTypes() {
         return this.$store.getters['furnishingTypes/furnishsingTypes'];
      },
   },
   methods: {
      clearValidity(input) {
         this[input].isValid = true
      },
      validateForm() {
         this.formIsValid = true;
         if (this.furnishingName.val === '' || this.furnishingName.val.length > 10) {
            this.furnishingName.isValid = false;
            this.formIsValid = false;
         }
         if (this.furnishingType.val === '') {
            this.furnishingType.isValid = false;
            this.formIsValid = false;
         }
      },
      submitForm() {
         this.validateForm();

         if (!this.formIsValid) {
            return;
         }

         if (!this.formIsUpdate) {
            const formData = {
            name: this.furnishingName.val,
            type: this.furnishingType.val,
         };
         this.$emit('save-data', formData);
         } else {
            const formData = {
               furnishings_ID: this.furnishings_ID.val,
               name: this.furnishingName.val,
               type: this.furnishingType.val,
            };
         this.$emit('save-data', formData);
         }
      },
      receivedFurnishing() {
         return this.$store.getters['furnishing/furnishings'];
      },
   },
   created() {
      if (this.$route.params.update) {
         let furnishings = this.receivedFurnishing();

         for (let furnishing in furnishings) {
            if (furnishings[furnishing].furnishings_ID == this.$route.params.id) {
               this.furnishings_ID.val = this.$route.params.id;
               this.furnishingName.val = furnishings[furnishing].description;
               this.furnishingType.val = furnishings[furnishing].furnishings_type_furnishings_type_ID;
            }
         }
         this.formIsUpdate = true;
      } else {
         this.formIsUpdate = false;
      }
   },

}
</script>

<style>
.form-control {
margin: 0.5rem 0;
}

label {
font-weight: bold;
display: block;
margin-bottom: 0.5rem;
}

input[type='checkbox'] + label {
font-weight: normal;
display: inline;
margin: 0 0 0 0.5rem;
}

input,
textarea {
display: block;
width: 100%;
border: 1px solid #ccc;
font: inherit;
}

input:focus,
textarea:focus {
background-color: #f0e6fd;
outline: none;
border-color: #3d008d;
}

input[type='checkbox'] {
display: inline;
width: auto;
border: none;
}

input[type='checkbox']:focus {
outline: #3d008d solid 1px;
}

h3 {
margin: 0.5rem 0;
font-size: 1rem;
}

.invalid label {
color: red;
}

.invalid input,
.invalid textarea {
border: 1px solid red;
}
</style>
