<template>
  <div>
    <the-header/> 

    <transition name="slide">
      <section v-if="show">
          <base-card>
            <h2>Reserve now!</h2>
            <reservation-form @save-data="saveData" :error="error" :hotel_id="this.getID()"></reservation-form>
          </base-card>
        </section>
      </transition>
    </div>
</template>

<script>

import TheHeader from '../../components/layout/TheHeader.vue';
import ReservationForm from '../../components/reservation/ReservationForm.vue'
import BaseCard from '../../components/ui/BaseCard.vue'

export default {
  emits: ['save-data'],
  components: {
    ReservationForm,
    BaseCard,
    TheHeader,
  },
  methods: {
    async saveData(data) {
        this.isLoading = true;
        this.error = false;

        let result = await this.$store.dispatch('reservation/registerReservation', data);
        if (result) {
          this.isLoading = false;
          this.$router.replace('/hotels');
        } else {
          this.error = true;
        } 
    },
    getID() {
        return this.$route.params.id;
    },
    getRoomID() {
        return this.$route.params.roomID;
    },
    async loadhotel() {
      this.isLoading = true;

      const hotelData = {
        id: this.getID(),
      }

      try {
        await this.$store.dispatch('hotels/fetchHotelByID', hotelData);
      } catch (error) {
        this.error = error.message || 'Something failed!';
      }
      
      this.isLoading = false;
    },
    async loadRoom() {
      this.isLoading = true;

      const roomData = {
        id: this.getRoomID(),
      }

      try {
        await this.$store.dispatch('rooms/fetchRoomByID', roomData);
      } catch (error) {
        this.error = error.message || 'Something failed!';
      }
      
      this.isLoading = false;
    },
    async loadGuests() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('guests/fetchGuests');
      } catch (error) {
        this.error = error.message || 'Something failed!';
      }
      
      this.isLoading = false;
    },
    handleError(){
        this.error = null;
    },
  },
  data() {
    return {
         isLoading: false,
         error: null,
         show: false,
         duplicateReservation: false
      }
  },
  created() {
    this.loadhotel();
    this.loadRoom();
    this.loadGuests();
  },
  mounted() {
    this.show = true
  },
}
</script>

<style>
.form-control {
margin: 0.5rem 0;
}

label {
font-weight: bold;
display: block;
margin-bottom: 0.5rem;
}

input[type='checkbox'] + label {
font-weight: normal;
display: inline;
margin: 0 0 0 0.5rem;
}

input,
textarea {
display: block;
width: 100%;
border: 1px solid #ccc;
font: inherit;
}

input:focus,
textarea:focus {
background-color: #f0e6fd;
outline: none;
border-color: #3d008d;
}

input[type='checkbox'] {
display: inline;
width: auto;
border: none;
}

input[type='checkbox']:focus {
outline: #3d008d solid 1px;
}

h3 {
margin: 0.5rem 0;
font-size: 1rem;
}

.invalid label {
color: red;
}

.invalid input,
.invalid textarea {
border: 1px solid red;
}
.slide-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.slide-enter-active {
  transition: all 0.2s ease-out;
}

.slide-leave-active {
  transition: all 0.2s ease-in;
}

.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>