<template>
   <section>
    <base-card>
      <h2>Page not found</h2>
      <p>
        This page coult not be found - Here you can go to home
        <router-link to="/hotels">Home</router-link>
      </p>
    </base-card>
   </section>
 </template>