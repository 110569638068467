<template>
   <form @submit.prevent="submitForm">

      <div>
         <label for="res-details">Reservation details</label>
         
         <h5>Hotel</h5>
         <p v-for="hotel in receivedFilteredHotel" :key="hotel.id">
            {{ hotel.name }}, {{ hotel.street }} {{ hotel.streetnumber }}, {{ hotel.postal_code }} {{ hotel.place }}
         </p>
         
         <h5>Room</h5>
         <p v-for="room in receivedFilteredRoom" :key="room.id">
            {{ room.name }}, Price per day (CHF): {{ room.price_day }}
         </p>
      </div>

      <div class="form-control">
         <label for="guests">Who are you?</label>
         <select v-model.trim="guestId.val">
            <option v-for="guest in receivedGuests"
               :key="guest.id" 
               :value="guest.guests_ID">{{ guest.name }}
            </option>
         </select>
         <br>
         <p v-if="!guestId.isValid">Please select a guest.</p>
      </div>

      <div>
         <label for="startdate">Reservation Start</label>
         <datepicker v-model="startDate.val" />
         <br>
      </div>

      <div>
         <label for="enddate">Reservation End</label>
         <datepicker v-model="endDate.val" />
         <br>
         <p v-if="!endDate.isValid">The enddate cannot be before the start date.</p>
      </div>
     
      <p v-if="!formIsValid">Please fix the above errors and submit again.</p>
      <p v-if="error">Ohh no, your booking was taken! Please select another date.</p>
      <base-button>Reserve</base-button>
   </form>
</template>

<script>
import moment from 'moment'
import Datepicker from 'vue3-datepicker'

export default {
   emits: ['save-data'],
   props: ['error'],
   components: {
      Datepicker,
   },
   data() {
      return {
         hotelId: {
            val: this.getID(),
            isValid: true
         },
         roomId: {
            val: this.getRoomID(),
            isValid: true
         },
         guestId: {
            val: '',
            isValid: true
         },
         startDate: {
            val: new Date(),
            asDate: "",
            isValid: true
         },
         endDate: {
            val: new Date(),
            asDate: "",
            isValid: true
         },
         formIsValid: true,
      };
   },
   methods: {
      getID() {
        return this.$route.params.id;
      },
      getRoomID() {
         return this.$route.params.roomID;
      },
      clearValidity(input) {
         this[input].isValid = true
      },
      validateForm() {
         this.formIsValid = true;
         this.hotelId.isValid = true;
         this.roomId.isValid = true;
         this.guestId.isValid = true;
         this.startDate.isValid = true;
         this.endDate.isValid = true;

         // Prepare date format for startDate
         let startDateUnformatted = moment(this.startDate.val).format()
         let startDateArray = startDateUnformatted.split('T');
         let startDate = startDateArray[0];
         this.startDate.asDate = startDate

         // Prepare date format for endDate
         let endDateUnformatted = moment(this.endDate.val).format()
         let endDateArray = endDateUnformatted.split('T');
         let endDate = endDateArray[0];
         this.endDate.asDate = endDate

         // Calculate date difference
         let startMoment = moment(startDate);
         let endMoment = moment(endDate);
         let diff = endMoment.diff(startMoment,'days');

         if (diff < 1) {
         this.endDate.isValid = false;
         this.formIsValid = false;
         }

         if (!this.guestId.val) {
            this.guestId.isValid = false;
            this.formIsValid = false;
         }
      },
      submitForm() {
         this.validateForm();

         if (!this.formIsValid) {
            return;
         }

         const formData = {
            hotelId: this.hotelId.val,
            roomId: this.roomId.val,
            guestId: this.guestId.val,
            startDate: this.startDate.asDate,
            endDate: this.endDate.asDate,
         };
         this.$emit('save-data', formData);
      }
   },
   computed: {
      receivedFilteredHotel() {
         return this.$store.getters['hotels/hotels'];
      },
      receivedFilteredRoom() {
         return this.$store.getters['rooms/rooms'];
      },
      receivedGuests() {
         return this.$store.getters['guests/guests'];
      },
   },
}
</script>

<style>
.form-control {
margin: 0.5rem 0;
}

label {
font-weight: bold;
display: block;
margin-bottom: 0.5rem;
}

input[type='checkbox'] + label {
font-weight: normal;
display: inline;
margin: 0 0 0 0.5rem;
}

input,
textarea {
display: block;
width: 100%;
border: 1px solid #ccc;
font: inherit;
}

input:focus,
textarea:focus {
background-color: #f0e6fd;
outline: none;
border-color: #3d008d;
}

input[type='checkbox'] {
display: inline;
width: auto;
border: none;
}

input[type='checkbox']:focus {
outline: #3d008d solid 1px;
}

h3 {
margin: 0.5rem 0;
font-size: 1rem;
}

.invalid label {
color: red;
}

.invalid input,
.invalid textarea {
border: 1px solid red;
}

</style>
