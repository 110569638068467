<template>
<form @submit.prevent="submitForm">
   <div class="form-control" :class="{invalid: !name.isValid}">
      <label for="categoryname">Name</label>
      <input type="text" id="name" v-model.trim="name.val" @blur="clearValidity('name')"/>
      <p v-if="!name.isValid">Name cannot be empty or longer then 10 characters.</p>
   </div>
   <div class="form-control" :class="{invalid: !surname.isValid}">
      <label for="categoryname">Surname</label>
      <input type="text" id="surname" v-model.trim="surname.val" @blur="clearValidity('surname')"/>
      <p v-if="!surname.isValid">Surname cannot be empty or longer then 10 characters.</p>
   </div>
   <div class="form-control" :class="{invalid: !phone.isValid}">
      <label for="categoryname">Phone</label>
      <input type="text" id="phone" v-model.trim="phone.val" @blur="clearValidity('phone')"/>
      <p v-if="!phone.isValid">Phone cannot be empty or longer then 10 characters.</p>
   </div>
   <div class="form-control" :class="{invalid: !email.isValid}">
      <label for="categoryname">Email</label>
      <input type="text" id="email" v-model.trim="email.val" @blur="clearValidity('email')"/>
      <p v-if="!email.isValid">Email cannot be empty or longer then 10 characters.</p>
   </div>
   <div class="form-control" :class="{invalid: !street.isValid}">
      <label for="categoryname">Street</label>
      <input type="text" id="street" v-model.trim="street.val" @blur="clearValidity('street')"/>
      <p v-if="!street.isValid">Street cannot be empty or longer then 10 characters.</p>
   </div>
   <div class="form-control" :class="{invalid: !streetnumber.isValid}">
      <label for="categoryname">Streetnumber</label>
      <input type="text" id="streetnumber" v-model.trim="streetnumber.val" @blur="clearValidity('streetnumber')"/>
      <p v-if="!streetnumber.isValid">Streetnumber cannot be empty or longer then 10 characters.</p>
   </div>
   <div class="form-control">
      <label for="locality">Locality</label>
      <select v-model.trim="localityID.val">
         <option v-for="locality in receivedLocality"
            :key="locality.id" 
            :value="locality.locality_ID">{{ locality.postal_code }}
         </option>
      </select>
      <p v-if="!localityID.isValid">Please select a place.</p>
   </div>
   <p v-if="!formIsValid">Please fix the above errors and submit again.</p>
   <base-button v-if="!formIsUpdate">Register</base-button>
   <base-button v-else>Update</base-button>
</form>
</template>

<script>
export default {
   emits: ['save-data'],
   data() {
      return {
         guests_ID: {
            val: '',
            isValid: true
         },
         localityID: {
            val: '',
            isValid: true
         },
         name: {
            val: '',
            isValid: true
         },
         surname: {
            val: '',
            isValid: true
         },
         phone: {
            val: '',
            isValid: true
         },
         email: {
            val: '',
            isValid: true
         },
         street: {
            val: '',
            isValid: true
         },
         streetnumber: {
            val: '',
            isValid: true
         },
         formIsValid: true,
         formIsUpdate: false
      }
   },
   methods: {
      clearValidity(input) {
         this[input].isValid = true
      },
      validateForm() {
         this.formIsValid = true;
         this.name.isValid = true;
         this.surname.isValid = true;
         this.phone.isValid = true;
         this.email.isValid = true;
         this.street.isValid = true;
         this.streetnumber.isValid = true;
         this.localityID.isValid = true;

         if (this.name.val === '' || this.name.val.length > 10) {
            this.name.isValid = false;
            this.formIsValid = false;
         }
         if (!this.surname.val || this.surname.val === '' || this.surname.val.length > 10) {
            this.surname.isValid = false;
            this.formIsValid = false;
         }
         if (!this.phone.val || this.phone.val === '' || this.phone.val.length > 15) {
            this.phone.isValid = false;
            this.formIsValid = false;
         }
         if (!this.email.val || this.email.val === '' || this.email.val.length > 20) {
            this.email.isValid = false;
            this.formIsValid = false;
         }
         if (!this.street.val || this.street.val === '' || this.street.val.length > 20) {
            this.street.isValid = false;
            this.formIsValid = false;
         }
         if (!this.streetnumber.val || this.streetnumber.val === '' || this.streetnumber.val.length > 4 || ! Number.isInteger(parseInt(this.streetnumber.val))) {
            this.streetnumber.isValid = false;
            this.formIsValid = false;
         }
         if (!this.localityID.val) {
            this.localityID.isValid = false;
            this.formIsValid = false;
         }
      },
      submitForm() {
         this.validateForm();

         if (!this.formIsValid) {
            return;
         }

         if (!this.formIsUpdate) {
            const formData = {
            localityID: this.localityID.val,
            name: this.name.val,
            surname: this.surname.val,
            phone: this.phone.val,
            email: this.email.val,
            street: this.street.val,
            streetnumber: this.streetnumber.val,
         };
         this.$emit('save-data', formData);
         } else {
            const formData = {
               guests_ID: this.guests_ID.val,
               localityID: this.localityID.val,
               name: this.name.val,
               surname: this.surname.val,
               phone: this.phone.val,
               email: this.email.val,
               street: this.street.val,
               streetnumber: this.streetnumber.val,
            };
         this.$emit('save-data', formData);
         }
      },
      receivedGuest() {
         return this.$store.getters['guests/guests'];
      },
   },
   computed: {
      receivedLocality() {
         return this.$store.getters['localities/localities'];
      },
   },
   created() {
      if (this.$route.params.update) {
         let guests = this.receivedGuest();

         for (let guest in guests) {
            if (guests[guest].guests_ID == this.$route.params.id) {
               this.guests_ID.val = this.$route.params.id;
               this.name.val = guests[guest].name;
               this.surname.val = guests[guest].surname;
               this.phone.val = guests[guest].phone;
               this.email.val = guests[guest].email;
               this.street.val = guests[guest].street;
               this.streetnumber.val = guests[guest].streetnumber;
            }
         }
         this.formIsUpdate = true;
      } else {
         this.formIsUpdate = false;
      }
   },
}
</script>

<style>
.form-control {
margin: 0.5rem 0;
}

label {
font-weight: bold;
display: block;
margin-bottom: 0.5rem;
}

input[type='checkbox'] + label {
font-weight: normal;
display: inline;
margin: 0 0 0 0.5rem;
}

input,
textarea {
display: block;
width: 100%;
border: 1px solid #ccc;
font: inherit;
}

input:focus,
textarea:focus {
background-color: #f0e6fd;
outline: none;
border-color: #3d008d;
}

input[type='checkbox'] {
display: inline;
width: auto;
border: none;
}

input[type='checkbox']:focus {
outline: #3d008d solid 1px;
}

h3 {
margin: 0.5rem 0;
font-size: 1rem;
}

.invalid label {
color: red;
}

.invalid input,
.invalid textarea {
border: 1px solid red;
}
</style>
