export default {
   setHotels(state, payload) {
      state.hotels = payload;
   },
   setHotelFurnishing(state, payload) {
      state.hotelFurnishings = payload;
   },
   setHotelRooms(state, payload) {
      state.hotelRooms = payload;
   }
};