<template>
  <div>
   <div v-if="isLoading">
      <base-spinner></base-spinner>
   </div>

   <base-dialog :show="!!error" title="An error occured!" @close="handleError">
      <p>{{ error }}</p>
   </base-dialog>

   <div v-if="isAdmin">
      <Sidebar />
      <div :style="{ 'margin-left': sidebarWidth }">
         <the-header/> 
         
         <transition name="slide">
            <section v-if="show">
               <header>
                  <h2>HOTELS MAINTENACE</h2>
               </header>

               <base-card class="center">
                  <div class="actions">
                     <base-button v-if="isAdmin" link :to="createHotel">Add hotel</base-button>
                  </div>
               </base-card>

               <hotel-filter @change-filter="setFilters"></hotel-filter>

               <div class="center">
                  <table class="childText" id="customers">
                     <tr>
                        <th>Hotel ID</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Postal code</th>
                        <th>Place</th>
                        <th>Street</th>
                        <th>Street Number</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th></th>
                     </tr>
                     <hotel-item v-for="hotel in filteredHotels" 
                        :key="hotel.id" 
                        :hotels_ID="hotel.hotels_ID"
                        :name="hotel.name"
                        :description="hotel.description"
                        :postal_code="hotel.postal_code"
                        :place="hotel.place"
                        :street="hotel.street"
                        :streetnumber="hotel.streetnumber"
                        :phone="hotel.phone"
                        :email="hotel.email"
                        :admin="isAdmin">
                     </hotel-item>
                  </table>
               </div>            
            </section>
         </transition>
      </div>
   </div>

   <div v-else>
      <the-header/>
      <hotel-filter @change-filter="setFilters"></hotel-filter>

      <transition name="slide">
         <section v-if="show">
            <base-card>
               <header>
                  <h2>HOTELS</h2>
               </header>
               <ul>
                  <hotel-item v-for="hotel in filteredHotels" 
                     :key="hotel.id"
                     :hotels_ID="hotel.hotels_ID" 
                     :name="hotel.name"
                     :description="hotel.description"
                     :postal_code="hotel.postal_code"
                     :place="hotel.place"
                     :street="hotel.street"
                     :streetnumber="hotel.streetnumber"
                     :phone="hotel.phone"
                     :email="hotel.email"
                     :admin="isAdmin">
                  </hotel-item>
               </ul>
            </base-card>
         </section>
      </transition>
   </div>
  </div>
</template>

<script>
import TheHeader from '../../components/layout/TheHeader.vue';
import Sidebar from '@/components/layout/sidebar/Sidebar';
import { sidebarWidth } from '@/components/layout/sidebar/state';
import HotelItem from '../../components/hotels/HotelItem.vue';
import HotelFilter from '../../components/hotels/HotelFilter.vue';

export default {
   components: {
      TheHeader,
      Sidebar,
      HotelItem,
      HotelFilter
   },
   setup() {
      return { sidebarWidth }
   },
   data() {
      return {
         isLoading: false,
         error: null,
         activeFilters: {
            oneStar: true,
            twoStar: true,
            threeStar: true,
            fourStar: true,
            fiveStar: true
         },
         isAdmin: false,
         show: false
   }
   },
   computed: {
      filteredHotels() {
            const hotels = this.$store.getters['hotels/hotels'];
            const filteredHotels = [];

            for (const filteredHotel in hotels) {
               if (this.activeFilters.oneStar && hotels[filteredHotel].description === '1-Stern') {
                  filteredHotels.push(hotels[filteredHotel]);
               }
               if (this.activeFilters.twoStar && hotels[filteredHotel].description === '2-Stern') {
                  filteredHotels.push(hotels[filteredHotel]);
               }
               if (this.activeFilters.threeStar && hotels[filteredHotel].description === '3-Stern') {
                  filteredHotels.push(hotels[filteredHotel]);
               }
               if (this.activeFilters.fourStar && hotels[filteredHotel].description === '4-Stern') {
                  filteredHotels.push(hotels[filteredHotel]);
               }
               if (this.activeFilters.fiveStar && hotels[filteredHotel].description === '5-Stern') {
                  filteredHotels.push(hotels[filteredHotel]);
               }
            }

            return filteredHotels;
         },
      receivedHotels() {
         return this.$store.getters['hotels/hotels'];
      },
      createHotel() {
         return this.$route.path + '/create';
      },
   },
   created() {
      this.loadHotels();
      this.isAdminCheck();
   },
   methods: {
      async loadHotels() {
         this.isLoading = true;

         try {
            await this.$store.dispatch('hotels/fetchHotels');
         } catch (error) {
            this.error = error.message || 'Something failed!';
         }
         
         this.isLoading = false;
      },
      handleError(){
         this.error = null;
      },
      setFilters(updatedFilters) {
            this.activeFilters = updatedFilters;
      },
      isAdminCheck() {
         if (this.$route.path.includes('admin'))
         {
            this.isAdmin = true;
         }
         return this.isAdmin;
      }
   },
   mounted() {
    this.show = true
  },
}
</script>

<style>
header {
  text-align: center;
}

ul {
  list-style: none;
  margin: 2rem auto;
  padding: 0;
  max-width: 30rem;
}

h3 {
  text-align: center;
}
.slide-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.slide-enter-active {
  transition: all 0.2s ease-out;
}

.slide-leave-active {
  transition: all 0.2s ease-in;
}

.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>