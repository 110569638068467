<template>
  <tr v-if="admin"> 
    <td>{{ hotels_ID }}</td>
    <td>{{ name }}</td>
    <td>{{ description }}</td>
    <td>{{ postal_code }}</td>
    <td>{{ place }}</td>
    <td>{{ street }}</td>
    <td>{{ streetnumber }}</td>
    <td>{{ phone }}</td>
    <td>{{ email }}</td>
    <td>
        <div class="actions">
          <base-button link :to="hotelsEditLink">Edit</base-button>
        </div>
    </td>
  </tr>
  <li v-else>
    <h3>{{ name }} - {{ description }}</h3>
    <div v-if="!admin" class="parent">
      <div class="child">
          <img  class="imageSize" src="../../assets/noImage.png" alt="">
      </div>
      <div v-if="!admin" class="childText">
          <p>{{ postal_code }} {{ place }}, {{ street }} {{ streetnumber }}</p>
          <p>{{ email }}, {{ phone }}</p>
      </div>
    </div>
    <div class="actions">
      <base-button v-if="!admin" link :to="hotelsDetailsLink">View details</base-button>
    </div>
  </li>
 </template>
 
 <script>
 export default {
   props: [
    'key',
    'hotels_ID',
    'name', 
    'description', 
    'postal_code', 
    'place', 
    'street', 
    'streetnumber', 
    'phone', 
    'email', 
    'admin'
  ],
   computed: {
      hotelsDetailsLink() {
         return this.$route.path + '/' + this.hotels_ID;
      },
      hotelsEditLink() {
         return this.$route.path + '/update/' + this.hotels_ID;
      },
   },
 }
 </script>
 
 <style scoped>
 li {
   margin: 1rem 0;
   border: 1px solid #424242;
   border-radius: 12px;
   padding: 1rem;
 }
 
 h3 {
   font-size: 1.5rem;
 }
 
 h3,
 h4 {
   margin: 0.5rem 0;
 }
 
 div {
   margin: 0.5rem 0;
 }
 
 .actions {
   display: flex;
   justify-content: center;
 }

 .parent {
  padding: 1rem 1rem;
  text-align: center;
}
.childText {
  display: inline-block;
  vertical-align: middle;
}

.imageSize {
   border-radius: 8px;
   width: 100px;
}
 </style>