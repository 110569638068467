<template>
   <li>
    <h3>{{ name }} - {{ description }}</h3>
    <div class="parent">
      <div class="child">
         <img  class="imageSize" src="../../assets/noImage.png" alt="">
      </div>
      <div class="childText">
         <p>{{ postal_code }} {{ place }}, {{ street }} {{ streetnumber }}</p>
         <p>{{ email }}, {{ phone }}</p>
      </div>
   </div>
   </li>
 </template>
 
 <script>
 export default {
   props: [
    'key',
    'hotels_ID',
    'name', 
    'description', 
    'postal_code', 
    'place', 
    'street', 
    'streetnumber', 
    'phone', 
    'email', 
    'admin'
  ],
   computed: {
      hotelsDetailsLink() {
         return this.$route.path + '/' + this.hotels_ID;
      },
      hotelsEditLink() {
         return this.$route.path + '/admin/hotels/' + this.key;
      },
   },
 }
 </script>
 
 <style scoped>
 li {
   margin: 1rem 0;
   border: 1px solid #424242;
   border-radius: 12px;
   padding: 1rem;
 }
 
 h3 {
   font-size: 1.5rem;
 }
 
 h3,
 h4 {
   margin: 0.5rem 0;
 }
 
 div {
   margin: 0.5rem 0;
 }
 
 .actions {
   display: flex;
   justify-content: center;
 }

 .parent {
  padding: 1rem 1rem;
  text-align: center;
}
.childText {
  display: inline-block;
  vertical-align: middle;
}

.imageSize {
   border-radius: 8px;
   width: 100px;
}
 </style>