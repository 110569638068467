export default {
   hotels(state) {
      return state.hotels;
   },
   hotelFurnishings(state) {
      return state.hotelFurnishings;
   },
   hotelRooms(state) {
      return state.hotelRooms;
   }
};