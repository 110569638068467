<template>
   <form @submit.prevent="submitForm">
      <div class="form-control" :class="{invalid: !localityPostal.isValid}">
         <label for="localitypostal">Postal code</label>
         <input type="text" id="localitypostal" v-model.trim="localityPostal.val" @blur="clearValidity('localityPostal')"/>
         <p v-if="!localityPostal.isValid">Postal code cannot be empty or longer then 4 numbers.</p>
      </div>
      <div class="form-control" :class="{invalid: !localityName.isValid}">
         <label for="localityname">Name of place</label>
         <input type="text" id="localityname" v-model.trim="localityName.val" @blur="clearValidity('localityName')"/>
         <p v-if="!localityName.isValid">Place cannot be empty or longer then 10 characters.</p>
      </div>
      <p v-if="!formIsValid">Please fix the above errors and submit again.</p>
      <base-button v-if="!formIsUpdate">Register</base-button>
      <base-button v-else>Update</base-button>
   </form>
   </template>
   
   <script>
   export default {
      emits: ['save-data'],
      data() {
         return {
            locality_ID: {
               val: '',
               isValid: true
            },
            localityPostal: {
               val: '',
               isValid: true
            },
            localityName: {
               val: '',
               isValid: true
            },
            formIsValid: true,
            formIsUpdate: false
         }
      },
      computed: {
         receivedLocalities() {
            return this.$store.getters['localities/localities'];
         },
      },
      methods: {
         clearValidity(input) {
            this[input].isValid = true
         },
         validateForm() {
            this.formIsValid = true;
            if (this.localityPostal.val === '' || this.localityPostal.val.length > 4 || ! Number.isInteger(parseInt(this.localityPostal.val))) {
               this.localityPostal.isValid = false;
               this.formIsValid = false;
            }
            if (this.localityName.val === '' || this.localityName.val.length > 10) {
               this.localityName.isValid = false;
               this.formIsValid = false;
            }
         },
         submitForm() {
            this.validateForm();
   
            if (!this.formIsValid) {
               return;
            }
   
            if (!this.formIsUpdate) {
               const formData = {
               postal: this.localityPostal.val,
               name: this.localityName.val,
            };
            this.$emit('save-data', formData);
            } else {
               const formData = {
               locality_ID: this.locality_ID.val,
               postal: this.localityPostal.val,
               name: this.localityName.val,
            };
            this.$emit('save-data', formData);
            }
         },
         receivedLocality() {
            return this.$store.getters['localities/localities'];
         },
      },
      created() {
      if (this.$route.params.update) {
         let localities = this.receivedLocality();

         for (let locality in localities) {
            if (localities[locality].locality_ID == this.$route.params.id) {
               this.locality_ID.val = this.$route.params.id;
               this.localityPostal.val = localities[locality].postal_code;
               this.localityName.val = localities[locality].place;
            }
         }
         this.formIsUpdate = true;
      } else {
         this.formIsUpdate = false;
      }
   },
   
   }
   </script>
   
   <style>
   .form-control {
   margin: 0.5rem 0;
   }
   
   label {
   font-weight: bold;
   display: block;
   margin-bottom: 0.5rem;
   }
   
   input[type='checkbox'] + label {
   font-weight: normal;
   display: inline;
   margin: 0 0 0 0.5rem;
   }
   
   input,
   textarea {
   display: block;
   width: 100%;
   border: 1px solid #ccc;
   font: inherit;
   }
   
   input:focus,
   textarea:focus {
   background-color: #f0e6fd;
   outline: none;
   border-color: #3d008d;
   }
   
   input[type='checkbox'] {
   display: inline;
   width: auto;
   border: none;
   }
   
   input[type='checkbox']:focus {
   outline: #3d008d solid 1px;
   }
   
   h3 {
   margin: 0.5rem 0;
   font-size: 1rem;
   }
   
   .invalid label {
   color: red;
   }
   
   .invalid input,
   .invalid textarea {
   border: 1px solid red;
   }
   </style>
   