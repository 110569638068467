export default {
   rooms(state) {
      return state.rooms;
   },
   roomFurnishings(state) {
      return state.roomFurnishings;
   },
   viewRoomFurnishings(state) {
      return state.viewRoomFurnishings;
   }
};
