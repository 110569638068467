<template>
<form @submit.prevent="submitForm">
      <div class="form-control" :class="{invalid: !name.isValid}">
         <label for="name">Name</label>
         <input type="text" id="name" v-model.trim="name.val" @blur="clearValidity('name')"/>
         <p v-if="!name.isValid">Name must not be empty.</p>
      </div>
      <div class="form-control" :class="{invalid: !street.isValid}">
         <label for="street">Street</label>
         <input type="text" id="street" v-model.trim="street.val" @blur="clearValidity('street')"/>
         <p v-if="!street.isValid">Street must not be empty.</p>
      </div>
      <div class="form-control" :class="{invalid: !streetnumber.isValid}">
         <label for="streetnumber">Streetnumber</label>
         <input type="text" id="streetnumber" v-model.trim="streetnumber.val" @blur="clearValidity('streetnumber')"/>
         <p v-if="!streetnumber.isValid">Streetnumber must not be empty.</p>
      </div>
      <div class="form-control" :class="{invalid: !phone.isValid}">
         <label for="phone">Phone</label>
         <input type="text" id="phone" v-model.trim="phone.val" @blur="clearValidity('phone')"/>
         <p v-if="!phone.isValid">Phone must not be empty.</p>
      </div>
      <div class="form-control" :class="{invalid: !email.isValid}">
         <label for="email">Email</label>
         <input type="text" id="email" v-model.trim="email.val" @blur="clearValidity('email')"/>
         <p v-if="!email.isValid">Email must not be empty.</p>
      </div>
      <div class="form-control">
         <label for="locality">Locality</label>
         <select v-model.trim="localityId.val">
            <option v-for="locality in receivedLocality"
               :key="locality.id" 
               :value="locality.locality_ID">{{ locality.postal_code }}
            </option>
         </select>
         <p v-if="!localityId.isValid">Please select a locality.</p>
      </div>
      <div class="form-control">
         <label for="category">Category</label>
         <select v-model.trim="starCategeoryId.val">
            <option v-for="category in receivedCategory"
               :key="category.id" 
               :value="category.star_category_ID">{{ category.description }}
            </option>
         </select>
         <p v-if="!starCategeoryId.isValid">Please select a category.</p>
      </div>
      <p v-if="!formIsValid">Please fix the above errors and submit again.</p>
      <base-button v-if="!formIsUpdate">Register</base-button>
      <base-button v-else>Update</base-button>
</form>
</template>

<script>
export default {
   emits: ['save-data'],
   data() {
      return {
         hotels_ID: {
            val: '',
            isValid: true
         },
         localityId: {
            val: '',
            isValid: true
         },
         starCategeoryId: {
            val: '',
            isValid: true
         },
         name: {
            val: '',
            isValid: true
         },
         street: {
            val: '',
            isValid: true
         },
         streetnumber: {
            val: '',
            isValid: true
         },
         phone: {
            val: '',
            isValid: true
         },
         email: {
            val: '',
            isValid: true
         },
         formIsValid: true,
         formIsUpdate: false
      }
   },
   computed: {
      receivedLocality() {
         return this.$store.getters['localities/localities'];
      },
      receivedCategory() {
         return this.$store.getters['category/categories'];
      },
   },
   methods: {
      clearValidity(input) {
         this[input].isValid = true
      },
      validateForm() {
         this.formIsValid = true;
         this.name.isValid = true;
         this.street.isValid = true;
         this.streetnumber.isValid = true;
         this.phone.isValid = true;
         this.email.isValid = true;
         this.localityId.isValid = true;
         this.starCategeoryId.isValid = true;

         if (this.name.val === '' || this.name.val.length > 20) {
            this.name.isValid = false;
            this.formIsValid = false;
         }
         if (this.street.val === '' || this.street.val.length > 20) {
            this.street.isValid = false;
            this.formIsValid = false;
         }
         if (this.streetnumber.val === '' || this.streetnumber.val.length > 4 || ! Number.isInteger(parseInt(this.streetnumber.val))) {
            this.streetnumber.isValid = false;
            this.formIsValid = false;
         }
         if (this.phone.val === '' || this.phone.val.length > 20) {
            this.phone.isValid = false;
            this.formIsValid = false;
         }
         if (this.email.val === '' || this.email.val.length > 20) {
            this.email.isValid = false;
            this.formIsValid = false;
         }
         if (!this.localityId.val) {
            this.localityId.isValid = false;
            this.formIsValid = false;
         }
         if (!this.starCategeoryId.val) {
            this.starCategeoryId.isValid = false;
            this.formIsValid = false;
         }
      },
      submitForm() {
         this.validateForm();

         if (!this.formIsValid) {
            return;
         }

         if (!this.formIsUpdate) {
            const formData = {
            localityId: this.localityId.val,
            starCategeoryId: this.starCategeoryId.val,
            name: this.name.val,
            street: this.street.val,
            streetnumber: this.streetnumber.val,
            phone: this.phone.val,
            email: this.email.val
         };
         this.$emit('save-data', formData);
         } else {
            const formData = {
               hotels_ID: this.hotels_ID.val,
               localityId: this.localityId.val,
               starCategeoryId: this.starCategeoryId.val,
               name: this.name.val,
               street: this.street.val,
               streetnumber: this.streetnumber.val,
               phone: this.phone.val,
               email: this.email.val
            };
            this.$emit('save-data', formData);
         }
      },
      receivedHotel() {
         return this.$store.getters['hotels/hotels'];
      },
   },
   created() {
      if (this.$route.params.update) {
         let hotels = this.receivedHotel();

         for (let hotel in hotels) {
            if (hotels[hotel].hotels_ID == this.$route.params.id) {
               this.hotels_ID.val = this.$route.params.id;
               this.name.val = hotels[hotel].name;
               this.street.val = hotels[hotel].street;
               this.streetnumber.val = hotels[hotel].streetnumber;
               this.phone.val = hotels[hotel].phone;
               this.email.val = hotels[hotel].email;
            }
         }
         this.formIsUpdate = true;
      } else {
         this.formIsUpdate = false;
      }
   },

}
</script>

<style>
.form-control {
margin: 0.5rem 0;
}

label {
font-weight: bold;
display: block;
margin-bottom: 0.5rem;
}

input[type='checkbox'] + label {
font-weight: normal;
display: inline;
margin: 0 0 0 0.5rem;
}

input,
textarea {
display: block;
width: 100%;
border: 1px solid #ccc;
font: inherit;
}

input:focus,
textarea:focus {
background-color: #f0e6fd;
outline: none;
border-color: #3d008d;
}

input[type='checkbox'] {
display: inline;
width: auto;
border: none;
}

input[type='checkbox']:focus {
outline: #3d008d solid 1px;
}

h3 {
margin: 0.5rem 0;
font-size: 1rem;
}

.invalid label {
color: red;
}

.invalid input,
.invalid textarea {
border: 1px solid red;
}
</style>
