<template>
<form @submit.prevent="submitForm">
   <div class="form-control" :class="{invalid: !description.isValid}">
      <label for="description">Price description</label>
      <input type="text" id="description" v-model.trim="description.val" @blur="clearValidity('description')"/>
      <p v-if="!description.isValid">Price description cannot be empty or longer then 10 characters.</p>
   </div>
   <div class="form-control" :class="{invalid: !price_day.isValid}">
      <label for="price_day">Price per day</label>
      <input type="text" id="price_day" v-model.trim="price_day.val" @blur="clearValidity('price_day')"/>
      <p v-if="!price_day.isValid">Price per day should have this format: 00.00.</p>
   </div>
   <p v-if="!formIsValid">Please fix the above errors and submit again.</p>
   
   <base-button v-if="!formIsUpdate">Register</base-button>
   <base-button v-else>Update</base-button>
</form>
</template>

<script>
export default {
   emits: ['save-data'],
   data() {
      return {
         price_class_ID: {
            val: '',
            isValid: true
         },
         description: {
            val: '',
            isValid: true
         },
         price_day: {
            val: '',
            isValid: true
         },
         formIsValid: true,
         formIsUpdate: false
      }
   },

   methods: {
      clearValidity(input) {
         this[input].isValid = true
      },
      validateForm() {
         this.formIsValid = true;
         if (this.description.val === '' || this.description.val.length > 10) {
            this.description.isValid = false;
            this.formIsValid = false;
         }
         if (this.price_day.val === '' || this.price_day.val.length > 6 || ! Number.isInteger(parseFloat(this.price_day.val))) {
            this.price_day.isValid = false;
            this.formIsValid = false;
         }
      },
      submitForm() {
         this.validateForm();

         if (!this.formIsValid) {
            return;
         }

         if (!this.formIsUpdate) {
            const formData = {
               description: this.description.val,
               price_day: this.price_day.val,
            };
            this.$emit('save-data', formData);
         } else {
            const formData = {
               price_class_ID: this.price_class_ID.val,
               description: this.description.val,
               price_day: this.price_day.val,
            };
            this.$emit('save-data', formData);
         }
      },
      receivedPrices() {
         return this.$store.getters['prices/prices'];
      },
   },
   created() {
      if (this.$route.params.update) {
         let prices = this.receivedPrices();

         for (let price in prices) {
            if (prices[price].price_class_ID == this.$route.params.id) {
               this.price_class_ID.val = this.$route.params.id;
               this.description.val = prices[price].description;
               this.price_day.val = prices[price].price_day;
            }
         }
         this.formIsUpdate = true;
      } else {
         this.formIsUpdate = false;
      }
   },
}
</script>

<style>
.form-control {
margin: 0.5rem 0;
}

label {
font-weight: bold;
display: block;
margin-bottom: 0.5rem;
}

input[type='checkbox'] + label {
font-weight: normal;
display: inline;
margin: 0 0 0 0.5rem;
}

input,
textarea {
display: block;
width: 100%;
border: 1px solid #ccc;
font: inherit;
}

input:focus,
textarea:focus {
background-color: #f0e6fd;
outline: none;
border-color: #3d008d;
}

input[type='checkbox'] {
display: inline;
width: auto;
border: none;
}

input[type='checkbox']:focus {
outline: #3d008d solid 1px;
}

h3 {
margin: 0.5rem 0;
font-size: 1rem;
}

.invalid label {
color: red;
}

.invalid input,
.invalid textarea {
border: 1px solid red;
}
</style>
