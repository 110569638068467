import config from '../../../config'

export default {
   async fetchHotels(context) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/view-hotels`, {
         method: 'GET',
      });
      
      
      if (!response.ok) {
         const error = new Error(response.message || 'Server is not responding!');
         throw error;
      }

      
      const responseData = await response.json();
      const hotels = [];
      
      for (const key in responseData) {
         const hotel = {
            id: key,
            hotels_ID: responseData[key].hotels_ID,
            locality_ID: responseData[key].locality_ID,
            name: responseData[key].name,
            star_category_ID: responseData[key].star_category_ID,
            description: responseData[key].description,
            postal_code: responseData[key].postal_code,
            place: responseData[key].place,
            street: responseData[key].street,
            streetnumber: responseData[key].streetnumber,
            phone: responseData[key].phone,
            email: responseData[key].email,
         }
         hotels.push(hotel);      
      }
      context.commit('setHotels', hotels);
   },
   async fetchHotelByID(context, data) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/view-hotels/byColumn?column=hotels_ID&argument=${data.id}`, {
         method: 'GET',
      });
      
      
      if (!response.ok) {
         const error = new Error(response.message || 'Failed to fetch!');
         throw error;
      }

      
      const responseData = await response.json();
      const hotels = [];
      
      for (const key in responseData) {
         const hotel = {
            id: key,
            hotels_ID: responseData[key].hotels_ID,
            locality_ID: responseData[key].locality_ID,
            name: responseData[key].name,
            star_category_ID: responseData[key].star_category_ID,
            description: responseData[key].description,
            postal_code: responseData[key].postal_code,
            place: responseData[key].place,
            street: responseData[key].street,
            streetnumber: responseData[key].streetnumber,
            phone: responseData[key].phone,
            email: responseData[key].email,
         }
         hotels.push(hotel);      
      }
      context.commit('setHotels', hotels);
   },
   async fetchHotelFurnishingsByID(context, data) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/view-hotel-furnishing/byColumn?column=hotels_ID&argument=${data.id}`, {
         method: 'GET',
      });
      
      
      if (!response.ok) {
         const error = new Error(response.message || 'Failed to fetch!');
         throw error;
      }

      
      const responseData = await response.json();
      const furnishings = [];
      
      for (const key in responseData) {
         const furnishing = {
            id: key,
            hotels_ID: responseData[key].hotels_ID,
            name: responseData[key].name,
            description: responseData[key].description,
            furnishings_type_ID: responseData[key].furnishings_type_ID,
         }
         furnishings.push(furnishing);      
      }
      context.commit('setHotelFurnishing', furnishings);
   },
   async fetchHotelRoomsByID(context, data) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/view-hotel-room/byColumn?column=hotels_ID&argument=${data.id}`, {
         method: 'GET',
      });
      
      
      if (!response.ok) {
         const error = new Error(response.message || 'Failed to fetch!');
         throw error;
      }

      
      const responseData = await response.json();
      const rooms = [];
      
      for (const key in responseData) {
         const room = {
            id: key,
            hotels_ID: responseData[key].hotels_ID,
            hotelName: responseData[key].hotelName,
            star_category_ID: responseData[key].star_category_ID,
            description: responseData[key].description,
            room_ID: responseData[key].room_ID,
            roomName: responseData[key].roomName,
            price_day: responseData[key].price_day
         }
         rooms.push(room);      
      }
      context.commit('setHotelRooms', rooms);
   },
   async registerHotel(context, data) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/hotel?localityId=${data.localityId}&starCategeoryId=${data.starCategeoryId}&name=${data.name}&street=${data.street}&streetnumber=${data.streetnumber}&phone=${data.phone}&email=${data.email}` , {
         method: 'POST',
      });

      const responseData = await response.json();

      if (!response.ok) {
         const error = new Error(responseData.message || 'Failed to create a new hotel!');
         throw error;
      }
   },
   async updateHotel(context, data) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/hotel?hotelId=${data.hotels_ID}&localityId=${data.localityId}&starCategeoryId=${data.starCategeoryId}&name=${data.name}&street=${data.street}&streetnumber=${data.streetnumber}&phone=${data.phone}&email=${data.email}` , {
         method: 'PUT',
      });

      const responseData = await response.json();

      if (!response.ok) {
         const error = new Error(responseData.message || 'Failed to create a new hotel!');
         throw error;
      }
   },
};