import config from '../../../config'

export default {
   async fetchCategory(context) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/starcategory`, {
         method: 'GET',
      });
      
      if (!response.ok) {
         const error = new Error(response.message || 'Server is not responding!');
         throw error;
      }

      
      const responseData = await response.json();
      const categories = [];
      
      for (const key in responseData) {
         const category = {
            id: key,
            star_category_ID: responseData[key].star_category_ID,
            description: responseData[key].description,
         }
         categories.push(category);      
      }
      context.commit('setCategory', categories);
   },
   async registerCategory(context, data) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/starcategory?description=${data.description}` , {
         method: 'POST',
      });

      const responseData = await response.json();

      if (!response.ok) {
         const error = new Error(responseData.message || 'Failed to create a new category!');
         throw error;
      }
   },
   async updateCategory(context, data) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/starcategory?starCategoryId=${data.star_category_ID}&description=${data.description}` , {
         method: 'PUT',
      });

      const responseData = await response.json();

      if (!response.ok) {
         const error = new Error(responseData.message || 'Failed to update your category!');
         throw error;
      }
   },
};
