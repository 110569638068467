<template>
<form @submit.prevent="submitForm">
   <div class="form-control" :class="{invalid: !roomName.isValid}">
      <label for="roomname">Room name</label>
      <input type="text" id="roomname" v-model.trim="roomName.val" @blur="clearValidity('roomName')"/>
      <p v-if="!roomName.isValid">Room name cannot be empty or longer then 10 characters.</p>
   </div>
   <div class="form-control">
      <label for="roomcategory">Price category CHF / day</label>
      <select v-model.trim="priceId.val">
         <option v-for="price in receivedPriceCategory"
            :key="price.id" 
            :value="price.price_class_ID">{{ price.price_day }}
         </option>
      </select>
      <p v-if="!priceId.isValid">Please select a price category.</p>
   </div>
   <div>
      <label for="furnishings">Select your furnishings</label>
      <span class="filter-option" v-for="furnishing in receivedFurnishing"
               :key="furnishing.id" 
               :value="furnishing.description">
         <div>
            <input type="checkbox" :id=furnishing.furnishings_ID @change="setFurnishing">
            <label :for=furnishing.description>{{ furnishing.description }}</label>
         </div>
      </span>
      <p v-if="!funishings.isValid">Please select at least one furnishing.</p>
   </div>
   <div class="form-control">
      <label for="hotelid">Assign room to a hotel</label>
      <select v-model.trim="hotelId.val">
         <option v-for="hotel in receivedHotels"
            :key="hotel.id" 
            :value="hotel.hotels_ID">{{ hotel.name }}
         </option>
      </select>
      <p v-if="!hotelId.isValid">Please select a hotel.</p>
   </div>
   <p v-if="!formIsValid">Please fix the above errors and submit again.</p>
   <base-button>Register</base-button>
</form>
</template>

<script>
export default {
   emits: ['save-data'],
   data() {
      return {
         roomName: {
            val: '',
            isValid: true
         },
         hotelId: {
            val: '',
            isValid: true
         },
         priceId: {
            val: '',
            isValid: true
         },
         funishings: {
            val: [],
            isValid: true
         },
         formIsValid: true,
      }
   },
   computed: {
      receivedFurnishing() {
         return this.$store.getters['rooms/roomFurnishings'];
      },
      receivedPriceCategory() {
         return this.$store.getters['prices/prices'];
      },
      receivedHotels() {
         return this.$store.getters['hotels/hotels'];
      },
   },
   methods: {
      setFurnishing(event) {
         const inputId = event.target.id;
         const isActive = event.target.checked;
         const updatedFrunishings = {
            ...this.funishings.val,
            [inputId]: isActive
         }
         this.funishings.val = updatedFrunishings;
      },
      clearValidity(input) {
         this[input].isValid = true
      },
      validateForm() {
         this.formIsValid = true;
         this.roomName.isValid = true;
         this.priceId.isValid = true;
         this.funishings.isValid = true;
         this.hotelId.isValid = true;

         if (this.roomName.val === '' || this.roomName.val.length > 10) {
            this.roomName.isValid = false;
            this.formIsValid = false;
         }
         if (this.priceId.val === '') {
            this.priceId.isValid = false;
            this.formIsValid = false;
         }
         if (this.funishings.val.length == 0) {
            this.funishings.isValid = false;
            this.formIsValid = false;
         }
         if (this.hotelId.val === '') {
            this.hotelId.isValid = false;
            this.formIsValid = false;
         }
      },
      submitForm() {
         this.validateForm();

         if (!this.formIsValid) {
            return;
         }

         const formData = {
            roomName: this.roomName.val,
            priceID: this.priceId.val,
            hotelId: this.hotelId.val,
            furnishings: this.funishings.val
         };
         this.$emit('save-data', formData);
      }
   }

}
</script>

<style>
.form-control {
margin: 0.5rem 0;
}

label {
font-weight: bold;
display: block;
margin-bottom: 0.5rem;
}

input[type='checkbox'] + label {
font-weight: normal;
display: inline;
margin: 0 0 0 0.5rem;
}

input,
textarea {
display: block;
width: 100%;
border: 1px solid #ccc;
font: inherit;
}

input:focus,
textarea:focus {
background-color: #f0e6fd;
outline: none;
border-color: #3d008d;
}

input[type='checkbox'] {
display: inline;
width: auto;
border: none;
}

input[type='checkbox']:focus {
outline: #3d008d solid 1px;
}

h3 {
margin: 0.5rem 0;
font-size: 1rem;
}

.invalid label {
color: red;
}

.invalid input,
.invalid textarea {
border: 1px solid red;
}
</style>
