import config from '../../../config'

export default {
   async fetchPrices(context) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/priceperday`, {
         method: 'GET',
      });
      
      
      if (!response.ok) {
         const error = new Error(response.message || 'Server is not responding!');
         throw error;
      }

      
      const responseData = await response.json();
      const prices = [];
      
      for (const key in responseData) {
         const price = {
            id: key,
            price_class_ID: responseData[key].price_class_ID,
            description: responseData[key].description,
            price_day: responseData[key].price_day
         }
         prices.push(price);      
      }
      context.commit('setPrices', prices);
   },
   async registerPrice(context, data) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/priceperday?description=${data.description}&price_day=${data.price_day}` , {
         method: 'POST',
      });

      const responseData = await response.json();

      if (!response.ok) {
         const error = new Error(responseData.message || 'Failed to create a new price!');
         throw error;
      }
   },
   async updatePrice(context, data) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/priceperday?priceClassId=${data.price_class_ID}&description=${data.description}&price_day=${data.price_day}` , {
         method: 'PUT',
      });

      const responseData = await response.json();

      if (!response.ok) {
         const error = new Error(responseData.message || 'Failed to update your price!');
         throw error;
      }
   },
};
