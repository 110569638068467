<template>
   <div>
      <div v-if="isLoading">
         <base-spinner></base-spinner>
      </div>

      <the-header/>
      <base-dialog :show="!!error" title="An error occured!" @close="handleError">
         <p>{{ error }}</p>
      </base-dialog>

      <transition name="slide">
         <section v-if="show">
            <base-card>
               <header>
                  <h2>YOUR HOTEL</h2>
               </header>
               <ul>
                  <hotel-item-detailed v-for="hotel in hotelByID" 
                     :key="hotel.id"
                     :hotels_ID="hotel.hotels_ID" 
                     :name="hotel.name"
                     :description="hotel.description"
                     :postal_code="hotel.postal_code"
                     :place="hotel.place"
                     :street="hotel.street"
                     :streetnumber="hotel.streetnumber"
                     :phone="hotel.phone"
                     :email="hotel.email">
                  </hotel-item-detailed>
               </ul>
            </base-card>

            <base-card>
               <header>
                  <h2>HOTEL FURNISHINGS</h2>
               </header>
               <ul>
                  <li class="liSub" v-for="hotel in hotelFurnishingByID"
                     :key="hotel.id">
                     {{ hotel.description }}
                  </li>
               </ul>
            </base-card>
            
            <base-card>
               <header>
                  <h2>YOUR ROOMS</h2>
               </header>
               <ul>
                  <li class="liSub" v-for="room in hotelRoomsByID"
                     :key="room.id">
                        <div class="center">
                           <h3 class="bitTitle">{{ room.roomName }}</h3>

                           <label for="price">Price per day CHF</label>
                           <base-card>
                              <p>{{ room.price_day }}</p>
                           </base-card>

                           <label for="furnishing">Includes</label>
                           <base-card>
                              <ul>
                                 <li class="liSub" v-for="furnishing in filteredFurnishings(room.room_ID)"
                                    :key="furnishing.id">
                                    {{ furnishing.description }}
                                 </li>
                              </ul>
                           </base-card>
                           
                           <base-button link :to="reserveRoom(room.room_ID)">Reserve!</base-button>
                        </div>
                  </li>
               </ul>
            </base-card>
         </section>
      </transition>
   </div>
</template>

<script>
import TheHeader from '../../components/layout/TheHeader.vue';
import HotelItemDetailed from '../../components/hotels/HotelItemDetailed.vue';

export default {
   components: {
      TheHeader,
      HotelItemDetailed,
   },
   data() {
      return {
         isLoading: false,
         error: null,
         show: false
      };
   },
   props: [
      'hotels_ID',
      'hotelName',
      'description', 
      'room_ID',
      'roomName',
      'price_day'
   ],
   computed: {
      hotelByID() {
         return this.$store.getters['hotels/hotels'];
      },
      hotelFurnishingByID() {
         return this.$store.getters['hotels/hotelFurnishings'];
      },
      hotelRoomsByID() {
         return this.$store.getters['hotels/hotelRooms'];
      },
      roomFurnishings() {
         return this.$store.getters['rooms/viewRoomFurnishings'];
      },
   },
   methods: {
      filteredFurnishings: function (id) {
         const furnishings = this.$store.getters['rooms/viewRoomFurnishings'];
         const filteredFurnishings = [];

         for (let furnishing in furnishings) {
            if(furnishings[furnishing].room_ID == id) {
               filteredFurnishings.push(furnishings[furnishing]);
            }
         } 
         return filteredFurnishings;
      },
      reserveRoom(id) {
         return this.$route.path + '/reserve/' + id;
      },
      getID() {
         return this.$route.params.id;
      },
      async loadHotelByID() {
         this.isLoading = true;

         const data = {
            id: this.getID(),
         };

         try {
            await this.$store.dispatch('hotels/fetchHotelByID', data);
         } catch (error) {
            this.error = error.message || 'Something failed!';
         }
         
         this.isLoading = false;
      },
      async loadHotelFurnishingByID() {
         this.isLoading = true;

         const data = {
            id: this.getID(),
         };

         try {
            await this.$store.dispatch('hotels/fetchHotelFurnishingsByID', data);
         } catch (error) {
            this.error = error.message || 'Something failed!';
         }
         
         this.isLoading = false;
      },
      async loadHotelRoomsByID() {
         this.isLoading = true;

         const data = {
            id: this.getID(),
         };

         try {
            await this.$store.dispatch('hotels/fetchHotelRoomsByID', data);
         } catch (error) {
            this.error = error.message || 'Something failed!';
         }
         
         this.isLoading = false;
      },
      async loadViewRoomFurnishing() {
         this.isLoading = true;

         try {
            await this.$store.dispatch('rooms/fetchViewRoomFurnishing');
         } catch (error) {
            this.error = error.message || 'Something failed!';
         }
         
         this.isLoading = false;
      },
      handleError(){
         this.error = null;
      },
   },
   created() {
      this.loadHotelByID();
      this.loadHotelFurnishingByID();
      this.loadHotelRoomsByID();
      this.loadViewRoomFurnishing();
   },
   mounted() {
    this.show = true
  },
}
</script>

<style>
.liSub {
   margin: 1rem 0;
   border: 1px solid #424242;
   border-radius: 12px;
   padding: 1rem;
}

.bitTitle {
   font-size: 1.5rem;
}

.center {
   text-align: center;
}

.imageSize {
   border-radius: 8px;
   width: 100px;
}
.slide-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.slide-enter-active {
  transition: all 0.2s ease-out;
}

.slide-leave-active {
  transition: all 0.2s ease-in;
}

.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>