import config from '../../../config'

export default {
   async fetchRooms(context) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/view-rooms`, {
         method: 'GET',
      });
      
      
      if (!response.ok) {
         const error = new Error(response.message || 'Server is not responding!');
         throw error;
      }

      
      const responseData = await response.json();
      const rooms = [];
      
      for (const key in responseData) {
         const room = {
            id: key,
            room_ID: responseData[key].room_ID,
            name: responseData[key].name,
            price_class_ID: responseData[key].price_class_ID,
            description: responseData[key].description,
            price_day: responseData[key].price_day,
         }
         rooms.push(room);      
      }
      context.commit('setRoom', rooms);
   },
   async fetchRoomFurnishing(context) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/furnishings`, {
         method: 'GET',
      });
      
      
      if (!response.ok) {
         const error = new Error(responseData.message || 'Failed to fetch!');
         throw error;
      }

      
      const responseData = await response.json();
      const roomFurnishings = [];
      
      for (const key in responseData) {
         const roomFurnishing = {
            id: key,
            furnishings_ID: responseData[key].furnishings_ID,
            furnishings_type_furnishings_type_ID: responseData[key].furnishings_type_furnishings_type_ID,
            description: responseData[key].description,
         }
         if (roomFurnishing.furnishings_type_furnishings_type_ID == 2) {
            roomFurnishings.push(roomFurnishing);  
         }
      }
      context.commit('setRoomFurnishing', roomFurnishings);
   },
   async fetchRoomByID(context, data) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/view-rooms/byColumn?column=room_ID&argument=${data.id}`, {
         method: 'GET',
      });
      
      
      if (!response.ok) {
         const error = new Error(responseData.message || 'Failed to fetch!');
         throw error;
      }

      
      const responseData = await response.json();
      const rooms = [];
      
      for (const key in responseData) {
         const room = {
            id: key,
            room_ID: responseData[key].room_ID,
            name: responseData[key].name,
            price_class_ID: responseData[key].price_class_ID,
            description: responseData[key].description,
            price_day: responseData[key].price_day,
         }
         rooms.push(room);      
      }
      context.commit('setRoom', rooms);
   },
   async fetchViewRoomFurnishing(context) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/view-room-furnishing`, {
         method: 'GET',
      });
      
      
      if (!response.ok) {
         const error = new Error(responseData.message || 'Failed to fetch!');
         throw error;
      }

      
      const responseData = await response.json();
      const roomFurnishings = [];
      
      for (const key in responseData) {
         const roomFurnishing = {
            id: key,
            room_ID: responseData[key].room_ID,
            name: responseData[key].name,
            description: responseData[key].description,
         }
         roomFurnishings.push(roomFurnishing);  
      }
      context.commit('setViewRoomFurnishing', roomFurnishings);
   },
   async registerRoom(context, data) {
      // Register the room
      const responseRoom = await fetch(`http://${config.hostname}:8080/api/v1/room?priceClassId=${data.priceID}&name=${data.roomName}` , {
         method: 'POST',
      });

      const responseDataRoom = await responseRoom.json();

      if (!responseRoom.ok) {
         const error = new Error(responseDataRoom.message || 'Failed to create a new furnishing!');
         throw error;
      }



      //Register the room hotel assignement
      const responseRoomHotel = await fetch(`http://${config.hostname}:8080/api/v1/hotels-room?hotelId=${data.hotelId}&roomId=${responseDataRoom[0].room_ID}` , {
         method: 'POST',
      });

      const responseDataRoomHotel = await responseRoomHotel.json();

      if (!responseRoomHotel.ok) {
         const error = new Error(responseDataRoomHotel.message || 'Failed to create a new furnishing!');
         throw error;
      }

      // Register room furnishings
      for (let furnishing in data.furnishings) {
         const responseRoomFurnishing = await fetch(`http://${config.hostname}:8080/api/v1/room-furnishing?roomId=${furnishing}&furnishingId=${responseDataRoom[0].room_ID}` , {
         method: 'POST',
         });

         const responseDataRoomFurnishing = await responseRoomFurnishing.json();

         if (!responseRoomFurnishing.ok) {
            const error = new Error(responseDataRoomFurnishing.message || 'Failed to create a new furnishing!');
            throw error;
         }

      }

   },
};
