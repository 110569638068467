import config from '../../../config'
import moment from 'moment'

export default {
   async fetchReservations(context) {
      const response = await fetch(`http://${config.hostname}:8080/api/v1/view-reservations`, {
         method: 'GET',
      });
      
      
      if (!response.ok) {
         const error = new Error(response.message || 'Server is not responding!');
         throw error;
      }

      
      const responseData = await response.json();
      const reservations = [];
      
      for (const key in responseData) {
         const reservation = {
            id: key,
            reservations_ID: responseData[key].reservations_ID,
            start_date: responseData[key].start_date,
            end_date: responseData[key].end_date,
            guests_ID: responseData[key].guests_ID,
            guestName: responseData[key].guestName,
            hotels_ID: responseData[key].hotels_ID,
            hotelName: responseData[key].hotelName,
            room_ID: responseData[key].room_ID,
            roomName: responseData[key].roomName,
            price_day: responseData[key].price_day,
         }
         reservations.push(reservation);      
      }
      context.commit('setReservations', reservations);
   },
   async registerReservation(context, data) {

      let canBook = true;
      let filteredReservations = []

      const response = await fetch(`http://${config.hostname}:8080/api/v1/view-reservations`, {
         method: 'GET',
      });

      const responseData = await response.json();

      for (const key in responseData) {
         if (responseData[key].room_ID == data.roomId) {
            const reservation = {
               id: key,
               reservations_ID: responseData[key].reservations_ID,
               start_date: responseData[key].start_date,
               end_date: responseData[key].end_date,
               room_ID: responseData[key].room_ID,
            }
            filteredReservations.push(reservation);
         }
      }

      for (let reservation in filteredReservations) {
         let startMoment = moment(filteredReservations[reservation].start_date);
         let endMoment = moment(filteredReservations[reservation].end_date);

         let newStartMoment = moment(data.startDate);
         let newEndMoment = moment(data.endDate);

         if (
            newStartMoment.isBetween(startMoment, endMoment) || 
            newEndMoment.isBetween(startMoment, endMoment) || 
            startMoment.isBetween(newStartMoment, newEndMoment) || 
            endMoment.isBetween(newStartMoment, newEndMoment) || 
            filteredReservations[reservation].start_date == data.startDate || 
            filteredReservations[reservation].start_date == data.endDate || 
            filteredReservations[reservation].end_date == data.startDate || 
            filteredReservations[reservation].end_date == data.endDate
            ) {
            canBook = false;
         }
         console.log(filteredReservations[reservation].end_date, data.endDate)
      }

      

      if (canBook) {
         const response = await fetch(`http://${config.hostname}:8080/api/v1/reservation?hotelId=${data.hotelId}&roomId=${data.roomId}&guestId=${data.guestId}&startDate=${data.startDate}&endDate=${data.endDate}` , {
            method: 'POST',
         });

         if (!response.ok) {
            return false;
         }
         return true;
      }
      else {
         return false;
      }
   },
};
