<template>
<form @submit.prevent="submitForm">
   <div class="form-control" :class="{invalid: !categoryName.isValid}">
      <label for="categoryname">Category description</label>
      <input type="text" id="categoryname" v-model.trim="categoryName.val" @blur="clearValidity('categoryName')"/>
      <p v-if="!categoryName.isValid">Category description cannot be empty or longer then 10 characters.</p>
   </div>
   <p v-if="!formIsValid">Please fix the above errors and submit again.</p>
   <base-button v-if="!formIsUpdate">Register</base-button>
   <base-button v-else>Update</base-button>
</form>
</template>

<script>
export default {
   emits: ['save-data'],
   data() {
      return {
         star_category_ID: {
            val: '',
            isValid: true
         },
         categoryName: {
            val: '',
            isValid: true
         },
         formIsValid: true,
         formIsUpdate: false
      }
   },

   methods: {
      clearValidity(input) {
         this[input].isValid = true
      },
      validateForm() {
         this.formIsValid = true;
         if (this.categoryName.val === '' || this.categoryName.val.length > 10) {
            this.categoryName.isValid = false;
            this.formIsValid = false;
         }
      },
      submitForm() {
         this.validateForm();

         if (!this.formIsValid) {
            return;
         }

         if (!this.formIsUpdate) {
            const formData = {
               description: this.categoryName.val,
            };
            this.$emit('save-data', formData);
         } else {
            const formData = {
               star_category_ID: this.star_category_ID.val,
               description: this.categoryName.val,
            };
            this.$emit('save-data', formData);
         }
      },
      receivedCategories() {
         return this.$store.getters['category/categories'];
      },
   },
   created() {
      if (this.$route.params.update) {
         let categories = this.receivedCategories();

         for (let category in categories) {
            if (categories[category].star_category_ID == this.$route.params.id) {
               this.star_category_ID.val = this.$route.params.id;
               this.categoryName.val = categories[category].description;
            }
         }
         this.formIsUpdate = true;
      } else {
         this.formIsUpdate = false;
      }
   },
}
</script>

<style>
.form-control {
margin: 0.5rem 0;
}

label {
font-weight: bold;
display: block;
margin-bottom: 0.5rem;
}

input[type='checkbox'] + label {
font-weight: normal;
display: inline;
margin: 0 0 0 0.5rem;
}

input,
textarea {
display: block;
width: 100%;
border: 1px solid #ccc;
font: inherit;
}

input:focus,
textarea:focus {
background-color: #f0e6fd;
outline: none;
border-color: #3d008d;
}

input[type='checkbox'] {
display: inline;
width: auto;
border: none;
}

input[type='checkbox']:focus {
outline: #3d008d solid 1px;
}

h3 {
margin: 0.5rem 0;
font-size: 1rem;
}

.invalid label {
color: red;
}

.invalid input,
.invalid textarea {
border: 1px solid red;
}
</style>
