<template>
   <tr>
      <td>{{ guests_ID }}</td>
      <td>{{ name }}</td>
      <td>{{ surname }}</td>
      <td>{{ phone }}</td>
      <td>{{ email }}</td>
      <td>{{ street }}</td>
      <td>{{ streetnumber }}</td>
      <td>{{ locality_ID }}</td>
      <td>{{ postal_code }}</td>
      <td>{{ place }}</td>
      <td>
         <div class="actions">
            <base-button link :to="guestEditLink">Edit</base-button>
         </div>
      </td>
   </tr>
 </template>

 <script>
 export default {
   props: [
    'key',
    'guests_ID', 
    'name',
    'surname', 
    'phone', 
    'email', 
    'street', 
    'streetnumber', 
    'locality_ID',  
    'postal_code', 
    'place', 
  ],
   computed: {
      guestEditLink() {
         return this.$route.path + '/update/' + this.guests_ID;
      },
   },
 }
 </script>
 
 <style scoped>
 li {
   margin: 1rem 0;
   border: 1px solid #424242;
   border-radius: 12px;
   padding: 1rem;
 }
 
 h3 {
   font-size: 1.5rem;
 }
 
 h3,
 h4 {
   margin: 0.5rem 0;
 }
 
 div {
   margin: 0.5rem 0;
 }
 
 .actions {
   display: flex;
   justify-content: center;
 }

 .parent {
  padding: 1rem 1rem;
  text-align: center;
}
.childText {
  display: inline-block;
  vertical-align: middle;
}

.imageSize {
   border-radius: 8px;
   width: 100px;
}
 </style>