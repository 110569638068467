export default {
   setRoom(state, payload) {
      state.rooms = payload;
   },
   setRoomFurnishing(state, payload) {
      state.roomFurnishings = payload;
   },
   setViewRoomFurnishing(state, payload) {
      state.viewRoomFurnishings = payload;
   }
};
