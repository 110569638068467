import { createRouter, createWebHashHistory } from 'vue-router';

import AdminOverview from '@/pages/admin/Overview';

import HotelsList from '@/pages/hotels/HotelsList';
import HotelDetailed from '@/pages/hotels/HotelDetailed';
import HotelRegristration from '@/pages/admin/hotels/HotelRegristration';

import RoomList from '@/pages/admin/room/RoomList';
import RoomRegristration from '@/pages/admin/room/RoomRegristration';

import FurnishingList from '@/pages/admin/furnishing/FurnishingList';
import FurnishingForm from '@/pages/admin/furnishing/FurnishingRegristration';

import GuestList from '@/pages/admin/guest/GuestList';
import GuestForm from '@/pages/admin/guest/GuestRegristration';

import LocalitiesList from '@/pages/admin/localities/LocalityList';
import LocalitiesForm from '@/pages/admin/localities/LocalityRegristration';

import CategoryList from '@/pages/admin/category/CategoryList';
import CategoryForm from '@/pages/admin/category/CategoryRegristration';

import PriceList from '@/pages/admin/price/PriceList';
import PriceForm from '@/pages/admin/price/PriceRegristration';

import ReservationList from '@/pages/admin/reservation/ReservationList';
import ReservationRoom from './components/reservation/ReservationRoom.vue'

import NotFound from '@/pages/NotFound';

const router= createRouter({
   history: createWebHashHistory(),
   routes: [
      { path: '/:notFound(.*)', component: NotFound },

      { path: '/', redirect: '/hotels' },
      { path: '/hotels', component: HotelsList },
      { path: '/hotels/:id', component: HotelDetailed },
      { path: '/hotels/:id/reserve/:roomID', component: ReservationRoom },

      { path: '/:admin', component: AdminOverview },
      { path: '/admin/hotels', component: HotelsList },
      { path: '/admin/hotels/create', component: HotelRegristration },
      { path: '/admin/hotels/:update/:id', component: HotelRegristration },

      { path: '/admin/rooms', component: RoomList },
      { path: '/admin/rooms/create', component: RoomRegristration },

      { path: '/admin/furnishings', component: FurnishingList },
      { path: '/admin/furnishings/create', component: FurnishingForm },
      { path: '/admin/furnishings/:update/:id', component: FurnishingForm },

      { path: '/admin/guests', component: GuestList },
      { path: '/admin/guests/create', component: GuestForm },
      { path: '/admin/guests/:update/:id', component: GuestForm },

      { path: '/admin/localities', component: LocalitiesList },
      { path: '/admin/localities/create', component: LocalitiesForm },
      { path: '/admin/localities/:update/:id', component: LocalitiesForm },

      { path: '/admin/categories', component: CategoryList },
      { path: '/admin/categories/create', component: CategoryForm },
      { path: '/admin/categories/:update/:id', component: CategoryForm },

      { path: '/admin/prices', component: PriceList },
      { path: '/admin/prices/create', component: PriceForm },
      { path: '/admin/prices/:update/:id', component: PriceForm },

      { path: '/admin/reservations', component: ReservationList },
      
   ],
   duplicateNavigationPolicy: 'reload'
});

export default router;
